import { useState } from 'react';
import { Button } from 'react-bootstrap';

// Assume referralCode is passed as a prop or retrieved from the state

const ReferralComponent = ({ referralCode }) => {
    const [copied, setCopied] = useState(false);

    const handleCopyReferralLink = () => {
        const referralLink = `https://bbrbassboatrentals.com/auth/signup-owner?ref=${referralCode}`;
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
            })
            .catch((error) => {
                console.error('Error copying referral link: ', error);
            });
    };

    return (
        <div>
            <h5>Your Referral Code: {referralCode}</h5>
            <Button className='mt-2' onClick={handleCopyReferralLink}>
                {copied ? 'Copied!' : 'Copy Referral Link'}
            </Button>
        </div>
    );
};

export default ReferralComponent;
