// Payment.js
import React, { useEffect, useState } from 'react';
import DataTable from '../DataTable';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../PaymentForm';
import { getOfferDetailsByBookingId } from '../utils';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_live_51OP31wGUDI27aRLukPP75NEf9vM9xP8zu8sIC5Nw20LUvA7qfrFHe5OCEvtjMtGLUEYEwLcp4d4sdu5eiNfrDtm2006843MTXC');


export default function BookingPayment() {

    const [storedPrice, setStoredPrice] = useState(null);
    const [offerData, setOfferData] = useState(null);

    const queryParameters = new URLSearchParams(window.location.search)
    const bookingId = queryParameters.get("bookingId");

    useEffect(() => {
      const fetchDetails = async () => {
          try {
            const offerDetails = await getOfferDetailsByBookingId(bookingId);
            console.log('offerDetails');
            console.log(offerDetails);
            const amountFinal = parseInt(offerDetails['offerPrice']+'00');
            setStoredPrice(amountFinal);
            setOfferData(offerDetails);
          } catch (error) {
            console.error('Error fetching listing details:', error);
          }
      };
      fetchDetails();
  }, []); // Empty dependency array means the effect runs once when the component mounts

  return (
    <>
      <div className="row">
        <div className="col">
          
              <div className='mt-4'>
                <Elements stripe={stripePromise}>
                  <PaymentForm amount={storedPrice} />
                </Elements>
              </div>
            </div>
      </div>
    </>
  );
}
