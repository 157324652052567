// SignupOwner.js
import { getDatabase, ref, set, update } from 'firebase/database';
import { useAuth } from '../../contexts/AuthContext';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Button, Form, ProgressBar, Row, Col } from 'react-bootstrap';
import { adminVerificationEmail, clientVerificationEmail, getAllVerificationQuestions, getCurrentUserDetails, getPlatformPercentages, newUserAddedAdminEmailBody, sendEmail, welcomeEmailBody } from '../utils'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'

const GetUserVerified = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [textQuestionAnswers, setTextQuestionAnswers] = useState([]);

  const [allQuestionsData, setAllQuestions] = useState([]);
  const [pendingVerification, setHandleVerification] = useState(false);

  const handlePendingVerification = (event) => {
    setHandleVerification(event.target.checked);
  };

  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {

        const allQuestionsData = await getAllVerificationQuestions();
        setAllQuestions(allQuestionsData);

        console.log('allQuestionsData');
        console.log(allQuestionsData);

        const user = await getCurrentUserDetails();
        setCurrentUser(user);

        console.log('user: ', user)

      } catch (error) {
        console.error('Error fetching Questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const handleChange = (e, question) => {
    const { name, value } = e.target;
  
    if (name.startsWith('question_')) {
      // Update textfield answers
      const questionIndex = parseInt(name.split('_')[1], 10);
      const existingAnswerIndex = textQuestionAnswers.findIndex(
        (answer) => answer.questionText === allQuestionsData[questionIndex].questionText
      );
  
      if (existingAnswerIndex !== -1) {
        // If an answer exists, update the existing answer
        setTextQuestionAnswers((prevAnswers) => {
          const updatedAnswers = [...prevAnswers];
          updatedAnswers[existingAnswerIndex] = {
            questionText: allQuestionsData[questionIndex].questionText,
            answer: value,
          };
          return updatedAnswers;
        });
      } else {
        // If no answer exists, add a new answer to the array
        setTextQuestionAnswers((prevAnswers) => [
          ...prevAnswers,
          {
            questionText: allQuestionsData[questionIndex].questionText,
            answer: value,
          },
        ]);
      }
    }
  };

  

  async function handleSubmit(e) {
    e.preventDefault();
  
    try {
      setError("");
      setLoading(true);

      const userRef = ref(getDatabase(), `users/${auth.currentUser.uid}`);
      await update(userRef, {
        status: 'Pending',
      });

      const userMetaRef = ref(getDatabase(), `userMeta/${auth.currentUser.uid}`);
      await update(userMetaRef, {
        userAnswers: {
          multiChoiceQuestions: allQuestionsData
            .filter(question => question.questionType === "option")
            .map((question, index) => {
              const questionIndex = allQuestionsData.findIndex(q => q.questionText === question.questionText);
              return {
                questionIndex,
                question: question.questionText,
                answer: selectedOptions.find(option => option.questionText === question.questionText)?.option || "",
              };
            }),
          textQuestions: allQuestionsData
            .filter(question => question.questionType === "text")
            .map((question, index) => {
              const questionIndex = allQuestionsData.findIndex(q => q.questionText === question.questionText);
              return {
                questionIndex,
                question: question.questionText,
                answer: textQuestionAnswers.find(answer => answer.questionText === question.questionText)?.answer || "",
              };
            }),
        },         
      });

      const userFullName = currentUser.firstName+' '+currentUser.lastName;
      const subject = 'Verification Application Submitted - '+userFullName;
      const to= currentUser.email;

      const emailBodyNew = await clientVerificationEmail(userFullName);
      const emailSent = await sendEmail(to, subject, emailBodyNew);
      const adminEmailBody = await adminVerificationEmail(userFullName);
      const adminEmailSent = await sendEmail('waivers@bbrbassboatrentals.com', `Verification Application - ${userFullName}`, adminEmailBody);
      
      setSuccess('Your request has been submitted and is under review. You will receive an email once you are approved.');

      window.location.reload()
    } catch (e) {
      setError(e.message);
      setSuccess('');
    } finally {
      setLoading(false);
    }
  }
  
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleRadioChange = (questionText, option) => {
    // Check if an option for the same question already exists
    const existingOptionIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.questionText === questionText
    );
  
    if (existingOptionIndex !== -1) {
      // If an option exists, update the existing option
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[existingOptionIndex] = {
          questionText,
          option,
        };
        return updatedOptions;
      });
    } else {
      // If no option exists, add a new option to the array
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        {
          questionText,
          option,
        },
      ]);
    }
  };
  
  return (

    <>
      <div class="page-description  page-description-tabbed">
        <h1 className='mb-0'>Get Verified</h1>
      </div>
      <hr/>
    <div className="auth-credentials m-b-xxl">
    <Row className="card p-3">
      <Col>
      {currentUser?.status != 'Pending' && currentUser?.status != 'Active' && 
        <Form onSubmit={handleSubmit}>
        {error && <Alert severity="error">{error}</Alert>}
            {allQuestionsData.map((question, index) => (
            <Form.Group key={index}>
              {question.questionType === "option" ? (
                <>
                  <Form.Label className="mt-2">{question.questionText}</Form.Label>
                  <div className="d-flex mcq-renter flex-wrap">
                    {question.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="mb-4">
                        <Form.Check
                          type="radio"
                          style={{ textAlign: 'start' }}
                          label={option}
                          className="btn btn-light m-1"
                          name={`question_${index}`}
                          id={`option_${index}_${optionIndex}`}
                          onChange={() => handleRadioChange(question.questionText, option)}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <Form.Label className="mt-3">{question.questionText}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name={`question_${index}`}
                    onChange={(e) => handleChange(e, question)}
                  />
                </>
              )}
            </Form.Group>
            ))}
            <Row className="mt-4">
            <FormGroup className="d-flex justify-content-center align-items-center">
              <div className="d-flex additional-box" style={{ width: '100%' }}>
                <FormControlLabel
                  style={{ alignItems: 'flex-start' }}
                  control={<Checkbox checked={pendingVerification} onChange={handlePendingVerification} />}
                />
                <div>
                  <p style={{ fontWeight: '500', lineHeight: '28px' }}>
                    <span style={{ fontSize: '12px', fontWeight: '500', borderRadius: '8px', background: '#1a1a1a', color: 'white', marginRight: '5px', padding: '4px 8px' }}>
                      Pending Verification
                    </span>
                    <span style={{ color: 'red' }}>*</span>
                  </p>
                  <p style={{ fontStyle: 'italic', marginTop: '-11px' }}>Your account will be under review. During this time, you won't be able to rent a Bass Boat, but you will still be able to rent other types of boats.</p>
                </div>
              </div>
            </FormGroup>
            </Row>
            {error && <Alert severity="warning">{error}</Alert>}
            <Row>
            <Col>
              <div className="auth-submit mt-4">
                <Button className="mx-2" variant="primary" type="submit">
                  {loading ? <ThreeDots
                    visible={true}
                    height="22"
                    width="22"
                    color="#fff"
                    radius="20"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    /> : 'Submit'
                  }
                </Button>
              </div>
              {success && <Alert className="mt-3" severity="success">{success}</Alert>}
            </Col>
            </Row>
        </Form>
      }

      {currentUser?.status == 'Active' && 
        <>
        <Row>
          <Col className='d-flex align-items-center gap-3'>
          <img className='img-responsive mt-3 mb-3' src="/inquiry-sent.png" style={{width: '20px', textAlign: 'center'}}/>      
          <h4 style={{fontWeight: 400, fontSize: '17px', lineHeight: '26px'}}>Your account is verified!</h4>
          </Col>
        </Row>
        </>
      }
      
    {currentUser?.status == 'Pending' &&
      <Row>
        <Col className='d-flex align-items-center gap-3'>
        <img className='img-responsive mt-3 mb-3' src="/inquiry-sent.png" style={{width: '20px', textAlign: 'center'}}/>      
        <h4 style={{fontWeight: 400, fontSize: '17px', lineHeight: '26px'}}>We're checking your request. Your account will be under review. During this time, you won't be able to rent a Bass Boat, but you will still be able to rent other types of boats.</h4>
        </Col>
      </Row>
    }
      </Col>
    </Row>
    </div>

    </>
  );
};

export default GetUserVerified;

