import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { WaiverSignedEmailBody, getBookingByID, getCurrentUserDetails, getInquiryDataById, getWaiverDataByName, getWaiverDescriptionByName, sendEmail } from '../utils';
import { get, getDatabase, push, ref, serverTimestamp, set, update } from 'firebase/database';
import SignatureCanvas from 'react-signature-canvas';

export default function TripWaivers() {
  const { dynamicId } = useParams();
  const navigate = useNavigate();
  const [adultFormResponses, setAdultFormResponses] = useState([]);
  const [minorFormResponses, setMinorFormResponses] = useState([]);
  const [waiverQuestions, setWaiverQuestions] = useState([]);
  const [adultWaiverQuestions, setAdultWaiverQuestions] = useState([]);
  const [minorWaiverQuestions, setMinorWaiverQuestions] = useState([]);
  const [userData, setUserData] = useState([]);
  const [inquiryDetails, setInquiryDetails] = useState([]);
  const signatureRef = React.useRef();

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };


  const handleAdultInputChange = (question, answer) => {
    if (question === 'date' || question === 'country') {
      // Handle date and country inputs
      const updatedResponses = [...adultFormResponses];
      const existingResponseIndex = updatedResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex].Answer = answer;
      } else {
        updatedResponses.push({ Question: question, Answer: answer });
      }
  
      setAdultFormResponses(updatedResponses);
    } else {
      const existingResponseIndex = adultFormResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        const updatedResponses = [...adultFormResponses];
        updatedResponses[existingResponseIndex].Answer = answer;
        setAdultFormResponses(updatedResponses);
      } else {
        setAdultFormResponses([...adultFormResponses, { Question: question, Answer: answer }]);
      }
    }
  };

  const handleMinorInputChange = (question, answer) => {
    if (question === 'date' || question === 'country') {
      // Handle date and country inputs
      const updatedResponses = [...minorFormResponses];
      const existingResponseIndex = updatedResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex].Answer = answer;
      } else {
        updatedResponses.push({ Question: question, Answer: answer });
      }
  
      setMinorFormResponses(updatedResponses);
    } else {
      const existingResponseIndex = minorFormResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        const updatedResponses = [...minorFormResponses];
        updatedResponses[existingResponseIndex].Answer = answer;
        setMinorFormResponses(updatedResponses);
      } else {
        setMinorFormResponses([...minorFormResponses, { Question: question, Answer: answer }]);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const thisUser = await getCurrentUserDetails();
    const sendToEmail = thisUser.email;
  
    try {
      // Define the path in the Firebase database
      const database = getDatabase();
      const adultTripDataRef = ref(database, `bookings/${dynamicId}/tripData/waivers/Adult Waiver`);
      
      const minorTripDataRef = ref(database, `bookings/${dynamicId}/tripData/waivers/Minor Waiver`);
      if (inquiryDetails.children > 0 || inquiryDetails.infants > 0) {

      const adultsQuestionsArray = adultFormResponses.map((response) => {
        let answer;
        if (response.type === 'signature') {
          answer = response.Answer[0];
        } else if (response.type === 'images') {
          // For images, use an array of URLs
          answer = response.Answer.map((image) => ({ url: image }));
        } else {
          // For other types, use the answer directly
          answer = response.Answer;
        }
  
        return {
          question: adultWaiverQuestions.find((q) => q.id === response.Question)?.question || 'Unknown',
          answer,
        };
      });

      await set(adultTripDataRef, { questions: adultsQuestionsArray });

      
      const subject = 'Congratulations! Minor / Infants Waiver Signed';
      const WaiverName = 'Minor, Infants Waiver';
      const url = '';
      const emailBody = await WaiverSignedEmailBody(WaiverName, url);
      sendEmail(sendToEmail, subject, emailBody);
    }

    if (inquiryDetails.children > 0 || inquiryDetails.infants > 0) {
      const minorQuestionsArray = minorFormResponses.map((response) => {
        let answer;
        if (response.type === 'signature') {
          answer = response.Answer[0];
        } else if (response.type === 'images') {
          // For images, use an array of URLs
          answer = response.Answer.map((image) => ({ url: image }));
        } else {
          // For other types, use the answer directly
          answer = response.Answer;
        }
  
        return {
          question: minorWaiverQuestions.find((q) => q.id === response.Question)?.question || 'Unknown',
          answer,
        };
      });

      await set(minorTripDataRef, { questions: minorQuestionsArray });
    }
      

      const bookingRef = ref(database, `bookings/${dynamicId}`);
      await update(bookingRef, { status: 'Paid' });
      
      const tripData = ref(database, `bookings/${dynamicId}/tripData`);
      await update(tripData, { startTimestamp: serverTimestamp() });

      const subject = 'Congratulations! Adult Passenger Waiver Signed';
      const WaiverName = 'Adult Passenger Waiver';
      const url = '';
      const emailBody = await WaiverSignedEmailBody(WaiverName, url);
      sendEmail(sendToEmail, subject, emailBody);



      navigate(`/dashboard/event/${dynamicId}`);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const [adultWaiverDescription, setAdultWaiverDescription] = useState([]);
  const [minorWaiverDescription, setMinorWaiverDescription] = useState([]);

  useEffect(() => {
    const fetchWaiverData = async () => {

      const bookingData = await getBookingByID(dynamicId);
      console.log('bookingData');
      console.log(bookingData);

      const inquiryData = await getInquiryDataById(bookingData.inquiryId);
      setInquiryDetails(inquiryData);
      console.log('inquiryData');
      console.log(inquiryData);

      if (inquiryData[0].inquiryData.children > 0 || inquiryData[0].inquiryData.infants > 0) {
        const waiverName = 'Minor Waiver';
        const questions = await getWaiverDataByName(waiverName);
        const questionsWithId = questions.map((question, index) => ({ ...question, id: index }));
        setMinorWaiverQuestions(questionsWithId);
        const description = await getWaiverDescriptionByName(waiverName);
        setMinorWaiverDescription(description);
      }
      

      if (inquiryData[0].inquiryData.adults > 0 || inquiryData[0].inquiryData.seniors > 0) {
        const waiverName = 'Adult Waiver';
        const questions = await getWaiverDataByName(waiverName);
        const questionsWithId = questions.map((question, index) => ({ ...question, id: index }));
        setAdultWaiverQuestions(questionsWithId);
        const description = await getWaiverDescriptionByName(waiverName);
        setAdultWaiverDescription(description);
      }
      const currentUserDetails = await getCurrentUserDetails();

      setUserData(currentUserDetails);
    };

    fetchWaiverData();
  }, []);


  console.log(waiverQuestions);


  

  return (
    <>
      
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Submit Waivers</h1>
          </div>
        </div>
      </div>

      <Row>
        <Col>
          <div className="card p-4">
            <form onSubmit={handleSubmit}>
                {adultWaiverQuestions && adultWaiverQuestions !==null ?
                <> <hr/>
                    <div className="row">
                    <div className="col">
                    <div className="page-description">
                        <h1>Adult Passenger Waiver</h1>
                        <div className='waiverDescrption mt-4' dangerouslySetInnerHTML={{ __html: adultWaiverDescription }} />
                    </div>
                    </div>
                </div></> : ''}
                {adultWaiverQuestions && adultWaiverQuestions !== null ? 
                  
                  adultWaiverQuestions.map((questionData) => (
                    <div key={`${questionData.question}-${questionData.id}`}>
                      <label className="form-label form-label mt-2">{questionData.question}:</label>
                      <br />
                      {questionData.type === 'text' ? (
                        <input
                          required
                          type="text"
                          className="form-control form-control"
                          value={adultFormResponses.find((response) => response.Question === questionData.id)?.Answer || ''}
                          onChange={(e) => handleAdultInputChange(questionData.id, e.target.value)} 
                        />
                      ) : questionData.type === 'date' ? (
                        <input
                          required
                          type="date"
                          className="form-control form-control"
                          value={adultFormResponses.find((response) => response.Question === 'date')?.Answer || ''}
                          onChange={(e) => handleAdultInputChange('date', e.target.value)} 
                        />
                      ) : questionData.type === 'country' ? (
                        <select
                          className="form-control"
                          name="settingsInputCountry"
                          value={adultFormResponses.find((response) => response.Question === 'country')?.Answer || ''}
                          onChange={(e) => handleAdultInputChange('country', e.target.value)} 
                        >
                          <option>United States</option>
                          {/* Add other country options if needed */}
                        </select>
                      ) : questionData.type === 'signature' ? (
                        <div>
                          <SignatureCanvas
                            ref={signatureRef}
                            required
                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                          />
                          <button type="button" onClick={() => signatureRef.current.clear()} className="btn btn-primary">
                            Clear
                          </button>
                        </div>
                      ) : (
                        questionData.options.map((option) => (
                          <div className="btn btn-light m-1 mb-4 form-check" key={option}>
                            <label htmlFor={option} className="form-check-label">
                              {option}
                            </label>
                            <input
                              required
                              type="radio"
                              name={questionData.id}
                              value={option}
                              id={option}
                              className="form-check-input"
                              checked={
                                adultFormResponses.find((response) => response.Question === questionData.id)?.Answer ===
                                option
                              }
                              onChange={() => handleAdultInputChange(questionData.id, option)}
                            />
                          </div>
                        ))
                      )}
                    </div>
                  ))
                : ''}  

                {minorWaiverQuestions && minorWaiverQuestions !==null ?
                 <> <hr/>
                 <div className="row">
                 <div className="col">
                 <div className="page-description">
                     <h1>Minor Passenger Waiver</h1>
                     <div className='waiverDescrption mt-4' dangerouslySetInnerHTML={{ __html: minorWaiverDescription }} />
                 </div>
                 </div>
                 </div></> : ''}
                {minorWaiverQuestions && minorWaiverQuestions !== null ? 
                  minorWaiverQuestions.map((questionData) => (
                    <div key={`${questionData.question}-${questionData.id}`}>
                      <label className="form-label form-label mt-2">{questionData.question}:</label>
                      <br />
                      {questionData.type === 'text' ? (
                        <input
                          required
                          type="text"
                          className="form-control form-control"
                          value={minorFormResponses.find((response) => response.Question === questionData.id)?.Answer || ''}
                          onChange={(e) => handleMinorInputChange(questionData.id, e.target.value)} 
                        />
                      ) : questionData.type === 'date' ? (
                        <input
                          required
                          type="date"
                          className="form-control form-control"
                          value={minorFormResponses.find((response) => response.Question === 'date')?.Answer || ''}
                          onChange={(e) => handleMinorInputChange('date', e.target.value)} 
                        />
                      ) : questionData.type === 'country' ? (
                        <select
                          className="form-control"
                          name="settingsInputCountry"
                          value={minorFormResponses.find((response) => response.Question === 'country')?.Answer || ''}
                          onChange={(e) => handleMinorInputChange('country', e.target.value)} 
                        >
                          <option>United States</option>
                          {/* Add other country options if needed */}
                        </select>
                      ) : questionData.type === 'signature' ? (
                        <div>
                          <SignatureCanvas
                            ref={signatureRef}
                            required
                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                          />
                          <button type="button" onClick={() => signatureRef.current.clear()} className="btn btn-primary">
                            Clear
                          </button>
                        </div>
                      ) : (
                        questionData.options.map((option) => (
                          <div className="btn btn-light m-1 mb-4 form-check" key={option}>
                            <label htmlFor={option} className="form-check-label">
                              {option}
                            </label>
                            <input
                              required
                              type="radio"
                              name={questionData.id}
                              value={option}
                              id={option}
                              className="form-check-input"
                              checked={
                                minorFormResponses.find((response) => response.Question === questionData.id)?.Answer ===
                                option
                              }
                              onChange={() => handleMinorInputChange(questionData.id, option)}
                            />
                          </div>
                        ))
                      )}
                    </div>
                  ))
                : ''}                
              <button type="submit" className="btn btn-primary mt-3">
                Submit
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
}
