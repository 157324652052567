import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LocationSearchInput from '../LocationSearchInput';
import { getEventsByUserId, getEventDetailsByEventID, getCurrentUserDetails, getAllEvents } from '../utils';
import { useAuth } from '../../contexts/AuthContext';
import { Alert } from '@mui/material';


const localizer = momentLocalizer(moment);
export default function Bookings() {

    const auth = useAuth();
    const currentUser = auth.currentUser;

    const Navigate = useNavigate();

    const [selectedEventDetails, setSelectedEventDetails] = useState([]);

    const[calendarEvents, setCalendarEvents] = useState([]);
  

  useEffect(() => {
    const fetchEvents = async () => {
        try {
            const userDetails = await getCurrentUserDetails();

            if(userDetails.role === 'Boat Owner' || userDetails.role === 'Renter') {
            const individualEvents = await getEventsByUserId(currentUser.uid);
            setCalendarEvents(individualEvents);
            } else if(userDetails.role === 'Admin') {
              const individualEvents = await getAllEvents(currentUser.uid);
              setCalendarEvents(individualEvents);
            }
        } catch (error) {
            console.error("Error fetching events:", error);
            // Handle the error, e.g., set calendarEvents to an empty array
            setCalendarEvents([]);
        }
    };

    fetchEvents();
}, [currentUser]);


  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color, // Use the color property from the event
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '1px solid #ccc',
      display: 'block',
      cursor: 'pointer',
    };

    return {
      style,
    };
  };

  const handleEventClick = (event) => {
    const selectedEvent = getEventDetailsByEventID(event.id)
    setSelectedEventDetails(selectedEvent);
    Navigate(`/dashboard/event/${event.id}`); // Update the URL or route as needed
  };
  return (
    <>
        <div class="row">
            <div class="col">
                <div class="page-description">
                    <h1>Bookings</h1>
                </div>
            </div>
        </div>
        <div class="row mb-3 align-items-center booking-labels-info">
            <div class="col-6 col-md-2 p-1">
                <h6 class="color-box text-center" style={{backgroundColor: 'rgb(255 0 0 / 70%)'}}>Pending</h6>
            </div>
            <div class="col-6 col-md-2 p-1">
                <h6 class="color-box text-center" style={{backgroundColor: 'rgb(101 31 255 / 70%)'}}>Waivers Pending</h6>
            </div>
            <div class="col-6 col-md-2 p-1" >
                <h6 class="color-box text-center" style={{backgroundColor: 'rgb(33 150 243 / 70%)'}}>Waivers Filled</h6>
            </div>
            <div class="col-6 col-md-2 p-1">
                <h6 class="color-box text-center" style={{backgroundColor: 'rgb(255 165 0 / 70%)'}}>Trip Started</h6>
            </div>
            <div class="col-6 col-md-2 p-1">
                <h6 class="color-box text-center" style={{backgroundColor: 'rgb(57 87 15 / 70%)'}}>Trip Completed</h6>
            </div>
            <div class="col-6 col-md-2 p-1">
                <h6 class="color-box text-center" style={{backgroundColor: 'rgb(0 128 0 / 70%)'}}>Trip Concluded</h6>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card calendar-container">
                    <div class="card-body cal-body">
                        <Calendar
                          localizer={localizer}
                          events={calendarEvents ? calendarEvents : ''}
                          startAccessor="start"
                          endAccessor="end"
                          style={{ height: 500 }}
                          eventPropGetter={eventStyleGetter}
                          onSelectEvent={handleEventClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
