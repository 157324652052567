import {useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'
import FloatingButtons from './frontend/FloatingButtons'

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
    <FloatingButtons/>
    <section className='cs-bg-dark py-4 cs-section'>
      <div className='container'>
        <Row className='mb-4'>
        <Col> 
          <Row>
            <Col>
              <img className='rounded' width={'100%'} src="/assets/frontend/images/statsBg.webp"/>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <h1 className='text-white'>ABOUT US</h1>
              <p className='text-white'>BBR is not just a booking platform; it's a dynamic community that fuels your passion for bass fishing. Whether you're craving the thrill of navigating a tournament ready bass boat or aiming to share your own vessel, BBR is your gateway to an unparalleled bass fishing experience. Our CEO and founder launched BBR in 2021 with a vision and a mission: to make bass boat rentals accessible on every recognized bass fishing lake in the USA. Join our community, where enthusiasts unite to embrace the excitement, fun and challenges of tournament bass fishing. Your adventure begins with BBR.</p>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <h2 className='text-white'>OUR SERVICES INCLUDE</h2>
              <ul className='text-white'>
                <li>Tournament ready bass boat rental with trailer</li>
                <li>Robust & streamlined online booking platform</li>
                <li>Online check-in and waiver system</li>
                <li>Renter Verification and approval system to ensure our boat operators are experienced.</li>
                <li>Delivery or Valet launch and load available.</li>
                <li>Rent by the hour, by the day or overnight up to 6 days.</li>
              </ul>
            </Col>
          </Row>
        </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col>
                    <img className='rounded' width={'100%'} src="/assets/frontend/images/takewithyou.webp"/>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col >
                    <h2 className='text-white'>Take it with you!</h2>
                    <p className='text-white'>Embark on your bass fishing adventure with BBR Bass Boat Rentals, where you can not only rent a tournament ready bass boat but also trailer it to your chosen inland freshwater haven. As our fleet grows to match your desires, stay tuned for listings near your favorite lake. </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} className='margin-top-mobile'>
                <Row>
                  <Col>
                    <img className='rounded' width={'100%'} src="/assets/frontend/images/ready.webp"/>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <h2 className='text-white'>Tournament ready!</h2>
                    <p className='text-white'>Discover the thrill of tournament ready bass boats on our booking platform, where we set a high standard for features to be considered tournament ready. At BBR, we're committed to surpassing expectations for both boat owners and renters. To dive into this unparalleled experience, ensure you're verified – check out our "GET VERIFIED" section for more details. Your next bass fishing journey starts with BBR! </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className='mt-4'>
                <Row>
                  <Col>
                    <img className='rounded' width={'100%'} src="/assets/frontend/images/boat.webp"/>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <h2 className='text-white'>What To Expect From Your Rental Boat</h2>
                    <ul className='text-white'>
                      <li>Bass boat including trailer for overnight rentals</li>
                      <li>Up to 250HP outboard motor</li>
                      <li>Tournament ready including, horn/whistle, fire extinguisher, nav lights, throwable PFD, life jackets, Kill switch and lanyard</li>
                      <li>Industry recognized marine electronics</li>
                      <li>Live wells</li>
                      <li>Hot foot available</li>
                      <li>Shallow water anchors available</li>
                      <li>GPS spot lock trolling motors available</li>
                      <li>FFS available</li>
                      <li>Lockable rod storage</li>
                      <li>Butt seat available</li>
                      <li>Safety response equipment available</li>
                      <li>First aid kit available</li>
                      <li>Boat cover available</li>
                      <li>Overnight trailer package includes vehicle safety kit, tire change kit, boat maintenance and wipe down kit, power cable. (available means check with the boat owner listing)</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>

    
    </>
  )
}
