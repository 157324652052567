// ProductList.js

import React, { useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProductList = ({ data, columns }) => {
  const [search, setSearch] = useState('');

  const {
    page,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
  );


  const containerStyle = {
    minHeight: '350px',
    borderRadius: '20px',
    padding: '3px',
    backgroundSize: 'cover',
    margin: '0px',
    backgroundPosition: 'center',
  };

  const priceTagStyle = {
    background: 'white',
    color: 'black',
    padding: '6px 15px',
    borderRadius: '10px',
    width: 'max-content',
    marginTop: '10px',
  };

  const boatFeaturesStyle = {
    gap: '20px',
    background: 'grey',
    borderRadius: '10px',
    color: 'white',
    padding: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  };

  const filteredPage = page.filter(row =>
    Object.values(row.values).some(
      value => value.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  return (
    <div>
      <div className="">
        <Row >
          {filteredPage.map((row, rowIndex) => (
            <Col key={rowIndex} md={4} className='mt-4'>
              <Link to={`/listing/${row.values.id}`} style={{textDecoration: "none"}}>
                <div
                  className="row productCard p-3"
                  style={{
                    ...containerStyle,
                    backgroundImage: `url(${row.values.backgroundImage ||
                      'https://images.pexels.com/photos/19415811/pexels-photo-19415811/free-photo-of-back-view-of-man-in-trench-coat-and-hat.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'})`,
                  }}
                >
                  <div className="col-12" style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <p>{row.values.price}</p>
                    <div className="row">
                      <div className="col-12"></div>
                      <div className="row">
                        <h3 style={{ padding: '0px', color: 'white' }}>{row.values.name}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="overlay"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    content: '""',
                    backgroundColor: 'rgba(0, 0, 0, 0.22)', // Adjust the color and opacity as needed
                  }}
                  ></div> */}
              </Link>
            </Col>
          ))}
        </Row>
      </div>

    </div>
  );
};

export default ProductList;
