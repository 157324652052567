// SignupOwner.js
import { getDatabase, ref, push, set, update, get, remove, onValue, off } from "firebase/database";

import { useAuth } from "../contexts/AuthContext";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { Button, Form, ProgressBar, Row, Col, Modal } from "react-bootstrap";
import { getListingCharterOptions, getListingFeaturesOptions, updateListingStatusById, getCurrentUserDetails, newListingAddedAdminEmailBody, sendEmail, getUserByID, getListingLockDatesByID } from "./utils";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Alert from "@mui/material/Alert";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import CreateListingDataTable from "./CreateListingDataTable";
import dayjs from 'dayjs';


const CreateListing = ({ initialData }) => {
  const serverUrl = "https://www.bbrbassboatrentals.com";
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [listingLockDates, setListingLockDates] = useState([]);

  const [pricing, setPricing] = useState({ 
    hourlyRate: 0, 
    overnightEnabled: true,
    hourlyOptions: [], 
    dailyOptions: [] 
  });

  const { overnightEnabled } = pricing; // Destructuring to get overnightEnabled from pricing state

  const toggleOvernight = () => {
    setPricing(prevState => ({
      ...prevState,
      overnightEnabled: !prevState.overnightEnabled // Toggle the value based on previous state
    }));
  };

  console.log('overnightEnabled');
  console.log(overnightEnabled);

  const { signup } = useAuth();
  const [error, setError] = useState("");
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('Pending');

  const [lockStartDate, setLockStartDate] = useState('');
  const [lockEndDate, setLockEndDate] = useState('');
  const [selectionRange, setSelectedRange] = useState([
    {
      startDate: addDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ]);

  const [boatTypes, setBoatTypes] = useState([]);
  useEffect(() => {
    const database = getDatabase();
    const boatTypesRef = ref(database, 'portalSettings/settingsID/boatTypes');
  
    const handleData = (snapshot) => {
      if (snapshot.exists()) {
        const boatTypesData = Object.entries(snapshot.val()).map(([id, value]) => ({
          id,
          value
        }));
        setBoatTypes(boatTypesData);
      } else {
        console.log('No data found');
        setBoatTypes([]);
      }
    };
  
    onValue(boatTypesRef, handleData);
  
    // Clean up the listener when the component unmounts
    return () => {
      // Detach the event listener
      off(boatTypesRef, 'value', handleData);
    };
  }, []);

  const handleHourlyOptionChange = (index, event) => {
    const { name, value } = event.target;
    const newOptions = [...pricing.hourlyOptions];
    newOptions[index][name] = value;
    setPricing({ ...pricing, hourlyOptions: newOptions });
  };

  const handleDailyOptionChange = (index, event) => {
    const { name, value } = event.target;
    const newOptions = [...pricing.dailyOptions];
    newOptions[index][name] = value;
    setPricing({ ...pricing, dailyOptions: newOptions });
  };

  const addHourlyOption = () => {
    setPricing({
      ...pricing,
      hourlyOptions: [...pricing.hourlyOptions, { hours: '', price: '' }],
    });
  };

  const addDailyOption = () => {
    setPricing({
      ...pricing,
      dailyOptions: [...pricing.dailyOptions, { days: '', price: ''}],
    });
  };

  const removeHourlyOption = (index) => {
    const newOptions = [...pricing.hourlyOptions];
    newOptions.splice(index, 1);
    setPricing({ ...pricing, hourlyOptions: newOptions });
  };

  const removeDailyOption = (index) => {
    const newOptions = [...pricing.dailyOptions];
    newOptions.splice(index, 1);
    setPricing({ ...pricing, dailyOptions: newOptions });
  };


  const isDateDisabled = (date) => {
    // Check if the date exists in listingLockDates array
    const dateString = date;
    console.log('date');
    console.log(date);
    return listingLockDates.includes(dateString);
  };

  const handleSelectedRange = (value) => {
    setSelectedRange(value);
    console.log('value');
    console.log(value);
  }

  const handleDateClick = (val) => {
    if (isDateDisabled(val)) {
      console.log('Disabled date clicked:', val);
      // Add your custom logic here
    } else {
      console.log('click');
    }
  }

  const [unlockStartDateRange, setUnlockStartDateRange] = useState(dayjs());
  const [unlockEndDateRange, setUnlockEndDateRange] = useState(dayjs());

  useEffect(() => {

    console.log('startDate');
    const startDate = selectionRange[0].startDate;

    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month since months are zero-indexed
    const day = String(startDate.getDate()).padStart(2, '0');
    const formattedStartDate = `${year}-${month}-${day}`;
    setLockStartDate(formattedStartDate);
    console.log(formattedStartDate)

    const endDate = selectionRange[0].endDate;

    const yearEnd = endDate.getFullYear();
    const monthEnd = String(endDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month since months are zero-indexed
    const dayEnd = String(endDate.getDate()).padStart(2, '0');
    const formattedEndDate = `${yearEnd}-${monthEnd}-${dayEnd}`;
    setLockEndDate(formattedEndDate);
    console.log('EndDate');
    console.log(formattedEndDate);
  },[selectionRange])

  const [disabledDates, setDisabledDates] = useState([]);

  useEffect(() => {
    // Extracting all disabled dates from the listingLockDates array
const disabledDates = listingLockDates.reduce((dates, item) => {
  console.log(dates,'new dates')
  const datesInRange = getDatesInRange(item.start_date, item.end_date);
  return [...dates, ...datesInRange];
}, []);
setDisabledDates(disabledDates);


  const data = listingLockDates.map(date => {
    return {
        date: <p> {date.start_date} <span className="fw-cs">to</span> {date.end_date}</p>,
        button: <Button className="btn btn-primary btn-cs-p" onClick={() => handleUnlockDate(date.id)}>Unlock Date</Button>
    };
  });

  setData(data);
  }, [listingLockDates])

  useEffect(() => {
    const fetchLockDates = async () => {
      try {
        const lockDates = await getListingLockDatesByID(initialData.id);
        console.log('lockDates:', lockDates);

        const lockDatesArray = [];

        Object.entries(lockDates).forEach(([id, date]) => {
            lockDatesArray.push({ id, start_date: formatDate(new Date(date.start_date)), end_date: formatDate(new Date(date.end_date)) });
        });

        setListingLockDates(lockDatesArray);
        
        console.log('lockDatesArray');
        console.log(lockDatesArray);
      } catch (error) {
        
      }
    }
    fetchLockDates();
  }, [initialData])

  function getDatesInRange(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);
    
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return dates;
}

  const [formData, setFormData] = useState({
    listingTitle: "",
    shortName: "",
    capacity: "",
    boatType: "",
    description: "",
    ownerId: auth.currentUser.uid,
    featured_image: null,
    storageAddress: "",
    make: "",
    model: "",
    year: "",
    hin: "",
    motorMake: "",
    motorModel: "",
    motorYear: "",
    motorSerial: "",
    trailerMake: "",
    trailerAxle: "",
    images: "",
    trailerShocks: "",
    trailerYear: "",
    trailerVin: "",
    trailerSpareWheel: "",
    trailerTireLegal: "",
    trollingMotorMake: "",
    trollingMotorModel: "",
    trollingMotorYear: "",
    trollingSpareTmProp: "",
    trollingBigMotorProp: "",
    shallowWaterAnchors: "",
    shallowWaterAnchorBrandModel: "",
    ffsMake: "",
    ffsModel: "",
    ffsYear: "",
    graph1Make: "",
    graph1Model: "",
    graph1Year: "",
    graph2Make: "",
    graph2Model: "",
    graph2Year: "",
    graph3Make: "",
    graph3Model: "",
    graph3Year: "",
    graph4Make: "",
    graph4Model: "",
    graph4Year: "",
    insuranceProvider: "",
    insuranceName: "",
    insuranceNumber: "",
    insuranceCoverage: "",
  });

  const [charterOptions, setCharterOptions] = useState([]);
  const [listingFeatures, setListingFeatures] = useState([]);

  const initialCheckboxes = listingFeatures.map((feature, index) => ({
    id: `checkbox${index + 1}`,
    label: feature,
    checked: false, 
  }));

  const [checkboxes, setCheckboxes] = useState(initialCheckboxes);

  const [userDetails, setUserDetails] = useState([]);

  const [showLockDatesModal, setLockDatesModal] = useState(false);

  const openLockDatesModal = () => {
    setLockDatesModal(true);
  }

  const closeLockDatesModal = () => {
    setLockDatesModal(false);
  }

  useEffect(() => { 
    const fetchQuestions = async () => {
      try {
        const charterOptions = await getListingCharterOptions();
        const charterOptionsArray = Object.values(charterOptions);
        setCharterOptions(charterOptionsArray);
  
        const listingFeatures = await getListingFeaturesOptions();
        const listingFeaturesArray = Object.values(listingFeatures);
        setListingFeatures(listingFeaturesArray);
  
        const userData = await getCurrentUserDetails();
        setUserDetails(userData);
  
        console.log('listingFeaturesArray');
        console.log(listingFeaturesArray);

                 
        if(initialData && initialData.features != null) {
        setSelectedCheckboxes(initialData.features);
        const initialDataArray = Object.values(initialData.features);
        // Append values from initialFeatures that are not already present in listingFeaturesArray
        initialDataArray.forEach((feature) => {
          if (!listingFeaturesArray.some((checkbox) => (checkbox.label || checkbox) == feature )) {
            const newCheckbox = {
              id: `checkbox${listingFeaturesArray.length + 1}`,
              label: feature.toString(),
              checked: false, // You might want to set this to the default value
            };
            listingFeaturesArray.push(newCheckbox);
          }
        });
      }
        const initialCheckboxes = listingFeaturesArray.map((feature, index) => {
          let isChecked;
          const featureLabel = typeof feature === 'object' ? feature.label : feature;
          if (initialData && initialData.features != null) {
            isChecked = initialData.features.includes(featureLabel);
          } else {
            isChecked = false;
          }
  
          return {
            id: `checkbox${index + 1}`,
            label: featureLabel,
            checked: isChecked,
          };
        });
        setCheckboxes(initialCheckboxes);
      } catch (error) {
        console.error("Error fetching listingFeaturesArray:", error);
      }
    };
  
    fetchQuestions();
  }, [initialData]);
  
  const getLocationName = async (lat, lng) => {
    try {

      const dropoffLat = lat;
      const dropoffLlng = lng;

      const dropOffResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${dropoffLat},${dropoffLlng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );
      
      const dropOffdata = await dropOffResponse.json();
      const dropoffName =
      dropOffdata.results[0]?.formatted_address || 'Location not found';
      return dropoffName;
    } catch (error) {
      console.error('Error fetching location name:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchListingLocationName = async () => {
      try {
        if(initialData) {
          const locationName = await getLocationName(initialData.storageAddress.lat, initialData.storageAddress.lng);
          setStorageAddress(locationName);
          console.log('locationName');
          console.log(locationName);
        }
      } catch (error) {
        console.log('Error ',error.message);
      }
    }
    fetchListingLocationName();
  }, [initialData])

  useEffect(() => {
    if (initialData) {
      setFormData((prevData) => ({
        ...prevData,
        listingId: initialData.listingId || "",
        listingTitle: initialData.listingTitle || "",
        shortName: initialData.shortName ||"",
        capacity:initialData.capacity || "",
        boatType:initialData.boatType || "",
        description:initialData.description || "",
        ownerId: auth.currentUser.uid,
        featured_image:initialData.featured_image || null,
        storageAddress: initialData.storageAddress ||"",
        make: initialData.make ||"",
        model:initialData.model || "",
        year:initialData.year || "",
        hin: initialData.hin ||"",
        images: initialData.images || "",
        motorMake:initialData.motorMake || "",
        motorModel: initialData.motorModel ||"",
        motorYear:initialData.motorYear || "",
        motorSerial: initialData.motorSerial ||"",
        trailerMake:initialData.trailerMake || "",
        trailerAxle: initialData.trailerAxle ||"",
        trailerShocks: initialData.trailerShocks ||"",
        trailerYear:initialData.trailerYear || "",
        trailerVin: initialData.trailerVin ||"",
        trailerSpareWheel:initialData.trailerSpareWheel || "",
        trailerTireLegal:initialData.trailerTireLegal || "",
        trollingMotorMake: initialData.trollingMotorMake ||"",
        trollingMotorModel: initialData.trollingMotorModel ||"",
        trollingMotorYear: initialData.trollingMotorYear ||"",
        trollingSpareTmProp: initialData.trollingSpareTmProp ||"",
        trollingBigMotorProp: initialData.trollingBigMotorProp ||"",
        shallowWaterAnchors: initialData.shallowWaterAnchors ||"",
        shallowWaterAnchorBrandModel:initialData.shallowWaterAnchorBrandModel || "",
        ffsMake: initialData.ffsMake ||"",
        ffsModel: initialData.ffsModel ||"",
        ffsYear: initialData.ffsYear ||"",
        graph1Make: initialData.graph1Make ||"",
        graph1Model: initialData.graph1Model ||"",
        graph1Year: initialData.graph1Year ||"",
        graph2Make:initialData.graph2Make || "",
        graph2Model:initialData.graph2Model || "",
        graph2Year:initialData.graph2Year || "",
        graph3Make:initialData.graph3Make || "",
        graph3Model:initialData.graph3Model || "",
        graph3Year:initialData.graph3Year || "",
        graph4Make:initialData.graph4Make || "",
        graph4Model:initialData.graph4Model || "",
        graph4Year:initialData.graph4Year || "",
        insuranceProvider:initialData.insuranceProvider || "",
        insuranceName: initialData.insuranceName ||"",
        insuranceNumber: initialData.insuranceNumber ||"",
        insuranceCoverage:initialData.insuranceCoverage || "",
      }));

      if (initialData.pricing) {
        const { hourlyRate, hourlyOptions, dailyOptions, overnightEnabled } = initialData.pricing;
        setPricing({
          hourlyRate: hourlyRate ?? 0,
          hourlyOptions: hourlyOptions ?? [],
          dailyOptions: dailyOptions ?? [],
          overnightEnabled: overnightEnabled ?? true
        });
      }

      const initialSelectedCheckboxes = checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: initialData.features?.includes(checkbox.label) || false,
      }));
      setCheckboxes(initialSelectedCheckboxes);
      setSelectedCheckboxes(initialSelectedCheckboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.label));
    }
    console.log('formData');
    console.log(formData);
  }, [initialData]);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [newCheckboxText, setNewCheckboxText] = useState('');

  // const handleCheckboxChange = (id, label) => {
  //   setCheckboxes((prevCheckboxes) =>
  //     prevCheckboxes.map((checkbox) =>
  //       checkbox.id === id
  //         ? { ...checkbox, checked: !checkbox.checked }
  //         : checkbox
  //     )
  //   );
  
  //   setSelectedCheckboxes((prevSelected) =>
  //     checkboxes
  //       .filter((checkbox) => checkbox.checked)
  //       .map((checkbox) => checkbox.label)
  //   );

  //   console.log('selectedCheckboxes');
  //   console.log(selectedCheckboxes);
  // };

  useEffect(() => {
    console.log('selectedCheckboxes:', selectedCheckboxes);
  }, [selectedCheckboxes]);

  const handleCheckboxChange = (id, label) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );

    setCheckboxes((updatedCheckboxes) => {
      setSelectedCheckboxes(
        updatedCheckboxes
          .filter((checkbox) => checkbox.checked)
          .map((checkbox) => checkbox.label)
      );
      return updatedCheckboxes;
    });
  };

  const handleAddCheckbox = (e) => {
    e.preventDefault(); // Prevent the form from submitting
  
    // Generate a unique id for the new checkbox
    const newCheckboxId = `checkbox_${new Date().getTime()}`;
  
    // Create a new checkbox object
    const newCheckbox = {
      id: newCheckboxId,
      label: newCheckboxText,
      checked: false,
    };
  
    // Update the state with the new checkbox
    setCheckboxes((prevCheckboxes) => [...prevCheckboxes, newCheckbox]);
  
    // Clear the input field
    setNewCheckboxText('');
  };
  
  

  const [success, setSuccess] = useState('');
  const handleStatusChange = async (event) => {
    try {
      const newStatus = event.target.value;
      setSelectedStatus(newStatus);
      await updateListingStatusById(initialData.listingId, newStatus);
      initialData.boatStatus = newStatus;
      setSuccess(`Listing status has been updated to ${newStatus}.`);
      setFormData((prevData) => ({ ...prevData, boatStatus: newStatus }));

      setTimeout(() => {
        setSuccess('');
      }, 1300);
      
    } catch (error) {
      setSuccess('');
    }
  };

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    async function handleLockDates() {
      try {
          if (initialData && initialData.listingId) {
              const listingsRef = ref(getDatabase(), `listings/${initialData.listingId}/lockDates`);

              await push(listingsRef, {start_date: lockStartDate, end_date: lockEndDate});

              setSuccess('Dates have been locked.');
              const lockDates = await getListingLockDatesByID(initialData.id);
              const lockDatesArray = [];

              Object.entries(lockDates).forEach(([id, date]) => {
                  lockDatesArray.push({ id, start_date: formatDate(new Date(date.start_date)), end_date: formatDate(new Date(date.end_date)) });
              });

              setListingLockDates(lockDatesArray);

              setTimeout(() => {
                  setSuccess('');
              }, 1300);
          }
      } catch (error) {
          setSuccess('');
      }
  }
  

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
  
      const featuresArray = selectedCheckboxes.map((checkbox) => checkbox);
  
      // Store user information in the Realtime Database under 'listings' node
      const listingsRef = push(ref(getDatabase(), 'listings/'));
      var boatStatus = 'Pending';

      if(formData.boatType == "Bass Boat"){
        boatStatus = 'Pending';
      }else{
        boatStatus = 'Active';
      }

      if(initialData) {
        if(initialData.boatStatus === 'Active') {
          boatStatus = 'Active';
        } else {
          boatStatus = 'Pending';
        }
    }
  
      
      // Upload listing details
      const listingDetails = {
        listingTitle:formData.listingTitle,
        shortName:formData.shortName,
        capacity:formData.capacity,
        boatType:formData.boatType,
        boatStatus:boatStatus,
        description:formData.description,
        ownerId: formData.ownerId,
        featured_image: formData.featured_image,
        storageAddress:formData.storageAddress,
        make:formData.make,
        model:formData.model,
        year:formData.year,
        hin:formData.hin,
        pricing,
        images: formData.images,
        motorMake:formData.motorMake,
        motorModel:formData.motorModel,
        motorYear:formData.motorYear,
        motorSerial:formData.motorSerial,
        trailerMake:formData.trailerMake,
        trailerAxle:formData.trailerAxle,
        trailerShocks:formData.trailerShocks,
        trailerYear:formData.trailerYear,
        trailerVin:formData.trailerVin,
        trailerSpareWheel:formData.trailerSpareWheel,
        trailerTireLegal:formData.trailerTireLegal,
        trollingMotorMake:formData.trollingMotorMake,
        trollingMotorYear:formData.trollingMotorYear,
        trollingMotorModel:formData.trollingMotorModel,
        trollingSpareTmProp:formData.trollingSpareTmProp,
        trollingBigMotorProp:formData.trollingBigMotorProp,
        shallowWaterAnchors:formData.shallowWaterAnchors,
        shallowWaterAnchorBrandModel:formData.shallowWaterAnchorBrandModel,
        ffsMake:formData.ffsMake,
        ffsModel:formData.ffsModel,
        ffsYear:formData.ffsYear,
        graph1Make:formData.graph1Make,
        graph1Model:formData.graph1Model,
        graph1Year:formData.graph1Year,
        graph2Make:formData.graph2Make,
        graph2Model:formData.graph2Model,
        graph2Year:formData.graph2Year,
        graph3Make:formData.graph3Make,
        graph3Model:formData.graph3Model,
        graph3Year:formData.graph3Year,
        graph4Make:formData.graph4Make,
        graph4Model:formData.graph4Model,
        graph4Year:formData.graph4Year,
        insuranceProvider:formData.insuranceProvider,
        insuranceName:formData.insuranceName,
        insuranceNumber:formData.insuranceNumber,
        insuranceCoverage: formData.insuranceCoverage,
        features: featuresArray,
      };
  
      // Add Images property and set it to an array of image URLs if selectedImages is an array
      // if (Array.isArray(selectedImages) && selectedImages.length > 0) {
      //   listingDetails.images = selectedImages.map((image) => URL.createObjectURL(image));
      // }
  
      // Update the featured_image property if it has been set
      if (formData.featured_image) {
        listingDetails.featured_image = formData.featured_image;
      }
      
      if(initialData) {
        if(initialData.listingId) {
          listingDetails.ownerId = initialData.ownerId;
          listingDetails.boatStatus = initialData.boatStatus;
          listingDetails.features = selectedCheckboxes;
          const listingsRef = ref(getDatabase(), `listings/${initialData.listingId}`);
          await update(listingsRef, listingDetails);
        }
      } else {
        await set(listingsRef, listingDetails);


        if(formData.boatType == "Bass Boat"){
          const ownerDetails = await getUserByID(formData.ownerId);
          const ownerName = ownerDetails.firstName+' '+ownerDetails.lastName;
          const bbrId = ownerDetails.bbrId;
          const adminEmailBody = await newListingAddedAdminEmailBody(ownerName, bbrId, formData.listingTitle);
          const adminEmailSent = await sendEmail('waivers@bbrbassboatrentals.com', 'New Bass Boat Listing Added.', adminEmailBody);
        }
       
      }

      navigate('/dashboard/my-listings');
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }
  
  const handleDeleteListing = async () => {
    try {
      const database = getDatabase();
      const listingRef = ref(database, `listings/${initialData.listingId}`);
      await remove(listingRef);
      setError('Listing has been deleted.');
  
      // Adding a timeout of 1.3 seconds before navigating
      setTimeout(() => {
        navigate('/dashboard/my-listings');
      }, 1300);
    } catch (error) {
      console.log(`Listing ${initialData.listingId} could not be removed, Error `, error);
    }
  }
  
  
  const [inputTitle, setInputTitle] = useState("");
  const [inputShortTitle, setInputShortTitle] = useState("");
  const [inputDescription, setInputDescription] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    setInputTitle(formData.listingTitle);
    setInputShortTitle(formData.shortName);
    setInputDescription(formData.description);
  }, [formData]);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [charterOption, setCharterOption] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleRadioChange = (questionText, option) => {
    // Check if an option for the same question already exists
    const existingOptionIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.questionText === questionText
    );

    if (existingOptionIndex !== -1) {
      // If an option exists, update the existing option
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[existingOptionIndex] = {
          questionText,
          option,
        };
        return updatedOptions;
      });
    } else {
      // If no option exists, add a new option to the array
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        {
          questionText,
          option,
        },
      ]);
    }
    setCharterOption(option);
  };
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  
  
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
  
    setSelectedImages(files);
  
    const formData = new FormData();
  
    files.forEach((file, index) => {
      formData.append('files', file); // Use 'files' as the key for each file
    });
    try {
      const response = await fetch(`${serverUrl}/upload-images`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('Server Response:', responseData);
      
        if (responseData.success) {
          // Use the server's response to update the state with image URLs
          setFormData((prevDetails) => ({
            ...prevDetails,
            images: responseData.file_names.map((filename) => `${serverUrl}/images/${filename}`),
          }));
          
      console.log('formData Images');
      console.log(formData);
        } else {
          console.error('File upload failed. Server returned:', responseData.message);
        }
      } else {
        console.error('File upload failed. Server returned:', response);
      }
      
          // Generate previews for selected images
    const previews = Array.from(files).map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });
  
    Promise.all(previews).then((results) => {
      setImagePreviews(results);
    });
    } catch (error) {
      console.error('Error uploading files:', error);
      console.error('Error stack:', error.stack);
    }
  };
  
  
  const [selectedFeaturedImage, setSelectedFeaturedImage] = useState(null);

  const handleFeaturedImageChange = async (e) => {
    const file = e.target.files[0];

    
    console.log(file);
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await fetch(`${serverUrl}/upload-featured-image`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const responseData = await response.json();
        setFormData((prevDetails) => ({
          ...prevDetails,
          featured_image: `${serverUrl}/images/${responseData.file_name}`, // Adjust to the actual path relative to your application directory
        }));
        setSelectedFeaturedImage(`${serverUrl}/images/${responseData.file_name}`);
      } else {
        console.error('File upload failed');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const [address, setAddress] = useState('');
  const [storageAddress, setStorageAddress] = useState('');

  const handlesStorageSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setFormData((prevData) => ({ ...prevData, storageAddress: latLng }));
      setStorageAddress(results[0].formatted_address);
    } catch (error) {
      console.error('Error fetching geolocation:', error);
    }
  };

  const handleUnlockDate = async (id) => {
    try {
      const listingsRef = ref(getDatabase(), `listings/${initialData.listingId}/lockDates/${id}`);
      await remove(listingsRef,'test test');
      console.log('Lock date deleted successfully.');
      
      // Remove the item from listingLockDates array
      const updatedListingLockDates = listingLockDates.filter(date => date.id !== id);
      setListingLockDates(updatedListingLockDates);
      setSuccess('Date has been unlocked.');
      setTimeout(() => {
        setSuccess('');
      }, 1300);

    } catch (error) {
      console.error('Error deleting lock date:', error);
      // You can add additional error handling here
    }
  };
  
  const [data, setData]= useState({});


  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Button',
      accessor: 'button',
    }
    // Add more columns as needed
  ];

  return (
    <>
    <Row className="px-2">
      <Col className="p-4 card">
        <Row className="mobile-direction-column-only">
          <Col xs={12} md={3}>
          <img
            className="thumbnailImg rounded"
            src={
              selectedFeaturedImage 
                ? selectedFeaturedImage 
                : (initialData && initialData.featured_image) 
                  ? initialData.featured_image 
                  : "/assets/backend/images/featured-img.webp"
            }
            alt="Featured Image"
          />
          </Col>
          <Col xs={12} md={6}>
            <h1 className="margin-top-mobile-10">{inputTitle || "Title here"}</h1>
            <h5>{inputShortTitle || "Short title here"}</h5>
            <p>{inputDescription || "Description here"}</p>
          </Col>
          <Col xs={12 } md={3} className="d-flex flex-column">
          {initialData && initialData.boatType === 'Bass Boat' && userDetails.role === 'Admin' ? 
            <div class="d-flex justify-content-center mb-2 mt-4">
            <select
              className="form-select"
              name="status"
              aria-label="Default select example"
              value={selectedStatus}
              onChange={(e) => handleStatusChange(e)}
            >
              <option value="Pending">Pending</option>
              <option value="Active">Active</option>
              <option value="Reject">Deny</option>
            </select>
          </div> : '' }

            {initialData && <button type="button" onClick={openLockDatesModal}  className="btn btn-primary mt-2"><i className="material-icons">https</i>Manage Availability</button>}
            { initialData && <button type="button" onClick={handleDeleteListing} className="btn btn-danger mt-2"><i className="material-icons">delete_outline</i>Remove</button> }
            {success && <Alert className="mt-3" severity="success">{success}</Alert>}
            {error && <Alert className="mt-3" severity="success">{error}</Alert>}
          </Col>
        </Row>
      </Col>
    </Row>
    <div className="row">
        <div className="col">
            <div className="card calendar-container">
                <div className="card-body">
                <div className="auth-credentials m-b-xxl">
                    <Row>
                      <Col>
                        <ProgressBar now={(step / 4) * 100} className="mb-4"/>
                        <Form onSubmit={handleSubmit}>
                          {step === 1 && (
                            <>
                              <Form.Group controlId="listingTitle">
                                <Row className="mb-2">
                                  <Col xs={12} md={6}>
                                    <Form.Label className="form-label">
                                      Listing Title
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="listingTitle"
                                      value={formData.listingTitle}
                                      onChange={handleChange}
                                      className="form-control"
                                      required
                                    />
                                  </Col>
                                  <Col  xs={12} md={6}>
                                    <Form.Label>Short Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="shortName"
                                      value={formData.shortName}
                                      onChange={handleChange}
                                      className="form-control"
                                      required
                                    />
                                  </Col>
                                </Row>
                          
                                <Row className="mb-2">
                                  <Col xs={12}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={5}
                                      name="description"
                                      value={formData.description}
                                      className="form-control"
                                      onChange={handleChange}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>                              
                              <Row className="mb-2">
                                <Col xs={12} md={6}>
                                  <Form.Label>Max Passengers Capacity*</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="capacity"
                                    value={formData.capacity}
                                    className="form-control"
                                    onChange={handleChange}
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={6}>
                                <Form.Label>Boat Type</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="boatType"
                                    value={formData.boatType}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option value="" selected>Select your boat type</option>
                                    {boatTypes.map((type, index) => (
                                      <option key={index} value={type.value}>{type.value}</option>
                                    ))}
                                  </Form.Control>
                                </Col>
                              </Row>
                                    
                              <Row className="mt-4">
                                <Col>
                                  <Button variant="btn btn-primary" onClick={nextStep}>
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                          {step === 2 && (
                            <>
                              <Row className="mb-2">
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>Featured Image</Form.Label>
                                    <Form.Control
                                      type="file"
                                      name="featuredImage"
                                      onChange={handleFeaturedImageChange}
                                      accept="image/*" // Limit to image files
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              
                              <Row className="mb-2">
                                <Col xs={12}>
                                  <Form.Label>Boat Location*</Form.Label>
                                  <div className='locationDiv'>
                                    <PlacesAutocomplete
                                      value={storageAddress}
                                      onChange={setStorageAddress}
                                      onSelect={handlesStorageSelect}
                                    >
                                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                          <div className="input-group">
                                            <input
                                              {...getInputProps({
                                                placeholder: 'Search Places...',
                                                className: 'location-search-input form-control',
                                                name: "location",
                                              })}
                                            />
                                            {/* <div className="input-group-append d-flex">
                                              <button
                                                className="btn btn-outline-dark"
                                                type="button"
                                                onClick={handleCurrentLocation}
                                              >
                                                <i className="fas fa-map-marker-alt"></i>
                                              </button>
                                            </div> */}
                                          </div>
                                          <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                              const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                              return (
                                                <div
                                                  {...getSuggestionItemProps(suggestion, {
                                                    className: 'location-suggestion form-control'
                                                  })}
                                                >
                                                  <span>{suggestion.description}</span>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                  </div>
                                </Col>
                              </Row>
                          
                              <Row className="mt-4">
                                <Col>
                                  <Button
                                    className=""
                                    variant="secondary"
                                    onClick={prevStep}
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    className="mx-3"
                                    variant="primary"
                                    onClick={nextStep}
                                  >
                                    Next
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                          {step === 3 && (
                            <>
                              <Form.Group>
                                <Form.Label>Add Gallery Images</Form.Label>
                                <Form.Control
                                  type="file"
                                  name="images"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                  multiple
                                />
                              </Form.Group>
                              {imagePreviews.length > 0 && (
                                <div className="image-gallery">
                                  {imagePreviews.map((preview, index) => (
                                    <img
                                      key={index}
                                      src={preview}
                                      alt={`Preview ${index}`}
                                      className="image-preview"
                                    />
                                  ))}
                                </div>
                              )}
                              {initialData && initialData.images && initialData.images.length > 0 && (
                                <div className="image-gallery">
                                  {initialData.images.map((image, index) => (
                                    <img
                                      key={index}
                                      src={image}
                                      alt={`Image ${index}`}
                                      className="image-preview"
                                    />
                                  ))}
                                </div>
                              )}
                              <Row>
                                <Col>
                                  <div className="auth mt-4">
                                    <Button
                                      className=""
                                      variant="secondary"
                                      onClick={prevStep}
                                    >
                                      Previous
                                    </Button>
                                    <Button className="mx-3" variant="btn btn-primary" onClick={nextStep}>
                                      Next
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                          {step === 4 && (
                            <>
                            
                              <Row className="mb-2">
                              <h5>Boat Specs*</h5>
                              </Row>
                              <Row className="mb-2">
                                <Col xs={12} md={6}>
                                  <Form.Label className="form-label">
                                    Make
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="make"
                                    value={formData.make}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Label>Model</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="model"
                                    value={formData.model}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col xs={12} md={6}>
                                  <Form.Label className="form-label">
                                  <Form.Label>{formData.boatType === 'Bass Boat' ? 'Year' : 'Motor Type'}</Form.Label>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="year"
                                    value={formData.year}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>  
                                <Col xs={12} md={6}>
                                  <Form.Label>{formData.boatType === 'Bass Boat' ? 'HIN' : 'Horse Power'}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="hin"
                                    value={formData.hin}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              {formData.boatType !== 'Bass Boat' && 
                                    <div>
                                    <h5 className="mt-4">Pricings</h5>
                                    <Form.Group as={Col} xs={12} md={6}>
                                      <Form.Label>Hourly Rate</Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="hourlyRate"
                                        value={pricing.hourlyRate}
                                        onChange={(e) => setPricing({ ...pricing, hourlyRate: e.target.value })}
                                        className="form-control"
                                        required
                                      />
                                    </Form.Group>
                                    <h6 className="mb-2 mt-3">Hourly Options</h6>
                                    {pricing.hourlyOptions.map((option, index) => (
                                      <Row key={index}>
                                        <Col xs={12} md={6}>
                                          <Form.Label>Hours</Form.Label>
                                          <Form.Control
                                            type="number"
                                            name="hours"
                                            value={option.hours}
                                            onChange={(e) => handleHourlyOptionChange(index, e)}
                                            className="form-control"
                                            required
                                          />
                                        </Col>
                                        <Col xs={12} md={6}>
                                          <Form.Label>Price</Form.Label>
                                          <Form.Control
                                            type="number"
                                            name="price"
                                            value={option.price}
                                            onChange={(e) => handleHourlyOptionChange(index, e)}
                                            className="form-control"
                                            required
                                          />
                                          
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-end mt-2">
                                          <Button variant="danger" onClick={() => removeHourlyOption(index)}>Remove</Button>
                                        </Col>
                                      </Row>
                                    ))}
                                    <Button onClick={addHourlyOption}>Add Hourly Option</Button>
                                      
                                    <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-2 mt-5">Overnight Options</h6>
                                    <Button variant={overnightEnabled ? 'danger' : 'primary'} onClick={toggleOvernight}>{overnightEnabled ? 'Disable' : 'Enable'} Overnight Options</Button>
                                    </div>
                                    {pricing.dailyOptions.map((option, index) => (
                                      <Row key={index}>
                                        <Col xs={12} md={4}>
                                          <Form.Label>Days</Form.Label>
                                          <Form.Control
                                            type="number"
                                            name="days"
                                            disabled={!overnightEnabled}
                                            value={option.days}
                                            onChange={(e) => handleDailyOptionChange(index, e)}
                                            className="form-control"
                                            required
                                          />
                                        </Col>
                                        <Col xs={12} md={4}>
                                          <Form.Label>Price</Form.Label>
                                          <Form.Control
                                            type="number"
                                            name="price"
                                            disabled={!overnightEnabled}
                                            value={option.price}
                                            onChange={(e) => handleDailyOptionChange(index, e)}
                                            className="form-control"
                                            required
                                          />
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-end mt-2">
                                          <Button variant="danger" onClick={() => removeDailyOption(index)}>Remove</Button>
                                        </Col>
                                      </Row>
                                    ))}
                                    <Button onClick={addDailyOption}>Add Daily Option</Button>
                                  </div>
                              }
                              {formData.boatType === 'Bass Boat' &&
                              <>
                              <Row className="mb-2 mt-4">
                                <h5>Motor Specs</h5>
                                <Col xs={12} md={6}>
                                  <Form.Label>Motor Make</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="motorMake"
                                    value={formData.motorMake}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Label>Model</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="motorModel"
                                    value={formData.motorModel}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                              <Col xs={12} md={6}>
                                  <Form.Label className="form-label">
                                  Year
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="motorYear"
                                    value={formData.motorYear}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              <Col xs={12} md={6}>
                                  <Form.Label>Serial</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="motorSerial"
                                    value={formData.motorSerial}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>   
                              <Row className="mb-2 mt-4">
                                <h5>Trailer Specs</h5>
                                <Col xs={12} md={6}>
                                  <Form.Label className="form-label">
                                  Trailer Make
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="trailerMake"
                                    value={formData.trailerMake}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={6}>
                                <Form.Label>Dual or Single Axle</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="trailerAxle"
                                    value={formData.trailerAxle}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option selected value="Dual">Dual</option>
                                    <option value="Single">Single</option>
                                  </Form.Control>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                              <Col xs={12} md={4}>
                              <Form.Label>Shocks</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="trailerShocks"
                                    value={formData.trailerShocks}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option selected value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                                <Col xs={12} md={4}>
                              <Form.Label>Surge Brakes</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="trailerSurgeBrakes"
                                    value={formData.trailerSurgeBrakes}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option selected value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label className="form-label">
                                  Year
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="trailerYear"
                                    value={formData.trailerYear}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                              <Col xs={12} md={6}>
                                  <Form.Label>VIN</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="trailerVin"
                                    value={formData.trailerVin}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              <Col xs={12} md={6}>
                                  <Form.Label>Spare Trailer Wheel</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="trailerSpareWheel"
                                    value={formData.trailerSpareWheel}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option selected value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                              </Row>      
                              <Row className="mb-2">
                              <Col xs={12}>
                                  <Form.Label>Are all trailer tire tread depths road legal including the spare?</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="trailerTireLegal"
                                    value={formData.trailerTireLegal}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                              </Row>      
                              <Row className="mb-2 mt-4">
                                <h5>Trolling Motor Specs</h5>
                                <Col xs={12} md={4}>
                                  <Form.Label className="form-label">
                                  Trolling Motor Make
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="trollingMotorMake"
                                    value={formData.trollingMotorMake}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label className="form-label">
                                  Trolling Motor Model
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="trollingMotorModel"
                                    value={formData.trollingMotorModel}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label className="form-label">
                                  Trolling Motor Year
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="trollingMotorYear"
                                    value={formData.trollingMotorYear}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                              <Col xs={12} md={6}>
                              <Form.Label>Spare Trolling Motor Prop</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="trollingSpareTmProp"
                                    value={formData.trollingSpareTmProp}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option selected value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                                <Col xs={12} md={6}>
                              <Form.Label>Big Motor Prop</Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="trollingBigMotorProp"
                                    value={formData.trollingBigMotorProp}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                              </Row> 
                              <Row className="mb-2 mt-4">
                                <h5>Shallow Water Anchors</h5>
                                <Col xs={12} md={6}>
                                  <Form.Label className="form-label">
                                  How many Shallow water anchors
                                  </Form.Label>
                                  <Form.Control
                                    as="select"  // Set the input type to "select"
                                    name="shallowWaterAnchors"
                                    value={formData.shallowWaterAnchors}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                    <option selected value="Yes">1</option>
                                    <option value="No">2</option>
                                  </Form.Control>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Label className="form-label">
                                  Shallow water anchor Brand and model
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="shallowWaterAnchorBrandModel"
                                    value={formData.shallowWaterAnchorBrandModel}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>  
                              <Row className="mb-2 mt-4">
                                <h5>FFS - Forward Facing Sonar Details</h5>
                                <Col xs={12} md={4}>
                                  <Form.Label>FFS Make</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="ffsMake"
                                    value={formData.ffsMake}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>FFS Model</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="ffsModel"
                                    value={formData.ffsModel}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>FFS Year</Form.Label>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="ffsYear"
                                    value={formData.ffsYear}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 1 Make</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph1Make"
                                    value={formData.graph1Make}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 1 Model</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph1Model"
                                    value={formData.graph1Model}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 1 Year</Form.Label>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="graph1Year"
                                    value={formData.graph1Year}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 2 Make</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph2Make"
                                    value={formData.graph2Make}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col  xs={12} md={4}>
                                  <Form.Label>Graph 2 Model</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph2Model"
                                    value={formData.graph2Model}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 2 Year</Form.Label>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="graph2Year"
                                    value={formData.graph2Year}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 3 Make</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph3Make"
                                    value={formData.graph3Make}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 3 Model</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph3Model"
                                    value={formData.graph3Model}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 3 Year</Form.Label>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="graph3Year"
                                    value={formData.graph3Year}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 4 Make</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph4Make"
                                    value={formData.graph4Make}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 4 Model</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="graph4Model"
                                    value={formData.graph4Model}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Graph 4 Year</Form.Label>
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="graph4Year"
                                    value={formData.graph4Year}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              <h5 className="mt-4">Features*</h5>
                              <p>Please select any significant features for your watercraft.</p>
                              <Row className="mb-3">
                                <Col  xs={12} md={4}>
                                  <input
                                    type="text"
                                    placeholder="Feature Name"
                                    value={newCheckboxText}
                                    onChange={(e) => setNewCheckboxText(e.target.value)}
                                    className="form-control"
                                  />
                                 
                                </Col>
                                <Col xs={12} md={4}>
                                  <button className="btn btn-primary" onClick={(e) => handleAddCheckbox(e)}>Add Feature</button>
                                </Col>
                              </Row>
                                <ul className="ks-cboxtags">
                                  {checkboxes.map((checkbox) => (
                                    <li key={checkbox.id} className={checkbox.checked ? 'ks-selected' : ''}>
                                      <input
                                        type="checkbox"
                                        id={checkbox.id}
                                        value={checkbox.label}
                                        checked={checkbox.checked}
                                        onChange={() => handleCheckboxChange(checkbox.id, checkbox.label)}
                                      />
                                      <label htmlFor={checkbox.id}>{checkbox.label}</label>
                                    </li>
                                  ))}
                                </ul>
                              <Row className="mb-2">
                              <h5>Insurance</h5>
                                <Col xs={12} md={4}>
                                  <Form.Label className="form-label">
                                    Insurance Provider
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="insuranceProvider"
                                    value={formData.insuranceProvider}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label className="form-label">
                                  Who is the owner of the policy named insured
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="insuranceName"
                                    value={formData.insuranceName}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Label>Policy number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="insuranceNumber"
                                    value={formData.insuranceNumber}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col  xs={12} md={5}>
                                  <Form.Label className="form-label">
                                  Does the policy include over 300k liability coverage acceptable to most tournament trails
                                  </Form.Label>
                                  <Form.Control
                                    as="select" 
                                    name="insuranceCoverage"
                                    value={formData.insuranceCoverage}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                  >
                                  <option selected value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  </Form.Control>
                                </Col>
                              </Row> 
                              </>
                              }
                              <Row>
                                <Col>
                                  <div className="auth mt-4">
                                    <Button
                                      variant="secondary"
                                      onClick={prevStep}
                                    >
                                      Previous
                                    </Button>
                                    <Button
                                      className="mx-3"
                                      variant="primary"
                                      type="submit"
                                    >
                                      Submit
                                    </Button>
                                  </div>

                                </Col>
                              </Row>
                            </>
                          )}
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <Modal size="lg" centered show={showLockDatesModal} onHide={closeLockDatesModal}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Availability</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={6} className="d-flex flex-column">
            <DateRange
              editableDateInputs={true}
              onChange={item => handleSelectedRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              ranges={selectionRange}
              disabledDates={disabledDates}
              onClickDate={(date) => handleDateClick(date)}
            />
            <Button className="mb-4 mt-3" onClick={handleLockDates} variant="primary">
            Lock Dates
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <CreateListingDataTable columns={columns} pageSize={5} data={data}/>
          </Col>
        </Row>
        
      </Modal.Body>
      <Modal.Footer>
      {success && <Alert severity='success'>{success}</Alert>}
      </Modal.Footer>
    </Modal> 
    </>
  );
};

export default CreateListing;
