// Payment.js
import DataTable from '../DataTable';
import { useAuth } from '../../contexts/AuthContext';
import { useEffect, useState } from 'react';
import { getUserByID, getCurrentUserDetails, getWithdrawalRequests, updateWithdrawalRequestStatus, getCurrentUserWithdrawalRequests } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { Alert } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import { getDatabase, ref, get, update } from 'firebase/database';
import { FaMoneyCheckAlt } from "react-icons/fa";

export default function Withdrawal() {

  const Navigate = useNavigate();
  const auth = useAuth();
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [currentBalance, setCurrentBalance] = useState([]);
  const [userDetails, setuserDetails] = useState([]);
  const [totalPending, setTotalPending] = useState(0);
  const [isRequestWithdrawalModalOpen, setRequestWithdrawalModalOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [selectedRowUserId, setSelectedRowUserId] = useState('');
  const [selectedRowAmount, selectedSelectedRowAmount] = useState('');
  const [selectedRowId, setSelectedRowId] = useState('');
  const [selectedRowName, setSelectedRowName] = useState('');
  const [selectedRowStatus, setSelectedRowStatus] = useState('');
  const [totalAmountRequested, setTotalAmountRequested] = useState(0);



  const openRequestWithdrawalModal = () => {
    setRequestWithdrawalModalOpen(true);
    setWithdrawalAmount(selectedRowAmount)
  }

  const closeRequestWithdrawalModal = () => {
    setRequestWithdrawalModalOpen(false);
  }

  const processWithdrawal = async () => {
    try {
      setSuccess('Withdrawal request has been approved.')

      await updateWithdrawalRequestStatus(selectedRowId, 'Approved')

      setSelectedRowStatus=('Approved');
      setTimeout(() => {
        setRequestWithdrawalModalOpen(false);
        setSuccess(false);
      }, 1000);

    } catch (error) {
      
    }
  } 

  const rejectWithdrawal = async () => {
    try {
      const database = getDatabase();

      console.log('selectedRowUserId');
      console.log(selectedRowUserId);
      const ownerMetaRef = ref(database, `ownerMeta/${selectedRowUserId}`);
    
      // Retrieve current values
      const currentPaymentsSnapshot = await get(ownerMetaRef);
      const currentPayments = currentPaymentsSnapshot.val();
  
      // Update currentAmount while keeping totalAmount same
      const updatedPayments = {
        currentAmount: parseInt(currentPayments.payments.currentAmount) + parseInt((withdrawalAmount.substring(1))*100),
        totalAmount: currentPayments.payments.totalAmount,
      }

      console.log(currentPayments);
      console.log('currentPayments');

      console.log(withdrawalAmount.substring(1));
      console.log('withdrawalAmount');

      console.log(updatedPayments);
      console.log('updatedPayments');
  
      // Use update instead of set to modify only the specified fields
      await update(ownerMetaRef, {payments: updatedPayments}); 

      setSuccess('Withdrawal request has been rejected.')
      setSelectedRowStatus=('Disapproved');

      await updateWithdrawalRequestStatus(selectedRowId, 'Disapproved')

      setTimeout(() => {
        setRequestWithdrawalModalOpen(false);
        setSuccess(false);
      }, 1000);

    } catch (error) {
      
    }
  } 

  const handleRowClick = (row) => {
    if (row && row.original && row.original.userId) {
      const userId = row.original.userId;
      const amount = row.original.amount;
      const id = row.original.id;
      const name = row.original.name;
      const status = row.original.status;
      setSelectedRowUserId(userId);
      selectedSelectedRowAmount(amount)
      setSelectedRowId(id)
      setSelectedRowName(name)
      openRequestWithdrawalModal()
      setSelectedRowStatus(status);
      console.log('status');
      console.log(status);
    } else {
      console.error('Invalid row object or missing bookingId');
    }
  };
  const handleRowClickOwner = (row) => {
    return;
  };
  

  useEffect(() => {

    const fetchUserData = async () => {
      try {
        const userData = await getCurrentUserDetails();
        setuserDetails(userData);

        let paymentDetails = [];
        
        if(userData.role === 'Admin') {
          paymentDetails = await getWithdrawalRequests();
        }
        else if (userData.role === 'Boat Owner') {
          paymentDetails = await getCurrentUserWithdrawalRequests(auth.currentUser.uid);
          console.log('paymentDetails');
          console.log(paymentDetails);
        } 

        setTotalAmountRequested(0);
        setTotalPending(0);

        const formattedData = paymentDetails.map(async (payment) => {

            const timestamp = payment.timestamp;
            const date = new Date(timestamp);

            if(payment.status === 'Pending') {
              setTotalPending((preValue) => preValue + 1);
              setTotalAmountRequested((prevAmount) => parseInt(prevAmount) + parseInt(payment.amount));
            }
            console.log('payment.amount');
            console.log(payment.amount);
            // Get various components of the date
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Months are zero-based
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Create a formatted date string
            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

            console.log(formattedDate);

            const userDetails = await getUserByID(payment.userId);
          return {
            id: payment.id,
            userId: payment.userId,
            name: userDetails.firstName + ' ' + userDetails.lastName,
            amount: '$'+payment.amount,
            status: payment.status,
            time: formattedDate,
            // Add more properties as needed
          };
        });

        Promise.all(formattedData).then((result) => {
          console.log('formattedData');
          console.log(formattedData);
          setFormattedPayments(result);
        });
      } catch (error) {
        console.error('Error fetching payment details:', error);
      }
    };

    fetchUserData();
  }, [auth.currentUser.uid]);

  const columnsAdmin = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'User ID', accessor: 'userId' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Amount', accessor: 'amount' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Time', accessor: 'time' },
  ];
  const columnsOwner = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Amount', accessor: 'amount' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Time', accessor: 'time' },
  ];

  // const data = [
  //   { name: 'John Doe', dateTime: '2023-01-15 10:30 AM', amount: 100.0, bookingId: 'B12345' },
  //   { name: 'Jane Doe', dateTime: '2023-02-02 03:45 PM', amount: 75.5, bookingId: 'B67890' },
  //   // Add more data rows as needed
  // ];

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Withdrawals</h1>
          </div>
        </div>
      </div>
      {userDetails && userDetails.role === 'Admin' ? 
      <Row>
        <Col xs={12} md={6}>
        <div class="card widget widget-bank-card">
            <div class="card-body">
                <div class="widget-bank-card-container widget-bank-card-mastercard d-flex flex-column">
                <div class="card-logo"><FaMoneyCheckAlt style={{width: '50px', height: '50px'}} /></div>
                    <span class="widget-bank-card-balance-title">
                      {userDetails.role === 'Boat Owner' ? 
                      'CURRENT BALANCE' : ''
                      }
                      {userDetails.role === 'Admin' ? 
                        'Withdraw Requests' : ''
                      }
                    </span>
                    <span class="widget-bank-card-balance">
                    {userDetails.role === 'Boat Owner' ? 
                    `${currentBalance ? currentBalance.currentAmount / 100 : 0}`
                    : ''}

                    {userDetails.role === 'Admin' ? 
                      `${totalPending} pending` : ''
                      }
                    </span>
                    <span class="widget-bank-card-number mt-auto">
                    {userDetails.role === 'Boat Owner' ? 
                        '**** **** **** 0999' : '' }
                    </span>
                </div>
            </div>
        </div>
        </Col>
        <Col xs={12} md={6}>
        <div class="card widget widget-bank-card">
            <div class="card-body">
                <div class="widget-bank-card-container widget-bank-card-visa d-flex flex-column">
                <div class="card-logo"><FaMoneyCheckAlt style={{width: '50px', height: '50px', color: 'white'}} /></div>
                    <span class="widget-bank-card-balance-title">
                    {userDetails.role === 'Boat Owner' ? 'ALL PAYMENTS' : ''}
                    {userDetails.role === 'Admin' ? 'TOTAL AMOUNT REQUESTED' : ''}                       
                    </span>
                    <span class="widget-bank-card-balance">
                    {userDetails.role === 'Boat Owner' ? currentBalance.totalAmount/100 : ''}
                    {userDetails.role === 'Admin' ? '$'+totalAmountRequested : ''}

                    </span>
                    <span class="widget-bank-card-number mt-auto">
                    </span>
                </div>
            </div>
        </div>
        </Col>
      </Row>
      : ''}

      <div className="row">
        <div className="col">
          <div className="card calendar-container">
            <div className="card-body">
              {userDetails.role === 'Admin' &&
                <DataTable columns={columnsAdmin} data={formattedPayments ? formattedPayments : ''} onRowClick={handleRowClick} />  
              }
              {userDetails.role === 'Boat Owner' &&
                <DataTable columns={columnsOwner} data={formattedPayments ? formattedPayments : ''} onRowClick={handleRowClickOwner} />
              }
            </div>
          </div>
        </div>
      </div>

      <Modal centered show={isRequestWithdrawalModalOpen} onHide={closeRequestWithdrawalModal}>
      <Modal.Header closeButton>
        <Modal.Title>Process Withdrawal Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Alert severity="info">You will have to transfer the money manually.</Alert>
        <Form.Label className='mt-3'>Boat Owner</Form.Label>
       <p>{selectedRowName ? selectedRowName : ''}</p>
      <Form.Group controlId="priceInput">
          <Form.Label className='mt-3'>Amount</Form.Label>
          <CurrencyInput
            placeholder="Enter Price"
            defaultValue={selectedRowAmount ? parseInt(selectedRowAmount.substring(1)) : 0}
            decimalsLimit={2}
            prefix='$'
            onValueChange={(value) => {
              // Set your maximum allowed value (e.g., $1000)
              const maxAllowedValue = selectedRowAmount ? parseInt(selectedRowAmount.substring(1)) : 4000;

              // Check if the entered value is within the allowed range
              if (value !== '') {
                const numericValue = parseFloat(value);
                if (!isNaN(numericValue) && numericValue <= maxAllowedValue) {
                  setWithdrawalAmount(value);
                  console.log(value);
                  setError('');
                } else if (!isNaN(numericValue) && numericValue >= maxAllowedValue){
                  setError('You cannot exceed above $4000');
                } else {
                  setError('');
                  setWithdrawalAmount(0);
                }
              } else {
                // If the value is empty, set it to a minimum value or the last valid value
                setWithdrawalAmount(0); // You can set it to a different minimum value if needed
                setError('')
              }
            }}
            className='form-control'
          />
            {selectedRowStatus && selectedRowStatus !== 'Pending' ? 
            <h6 className='mt-3' style={{textAlign: 'center'}}>
                Withdrawal requested {selectedRowAmount ? selectedRowAmount : ''}
            </h6>
            : ''}
          {error && (
            <Alert className='mt-3' severity="error">{error}</Alert>
          )}
          {success && (
            <Alert className='mt-3' severity="success">{success}</Alert>
          )}
          {selectedRowStatus && selectedRowStatus !== 'Pending' ?
          <Alert className='mt-3' severity="success">Withdrawal request has been dealt with</Alert> : ''
          }
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {selectedRowStatus && selectedRowStatus === 'Pending' ? <>
        <Button variant="danger" onClick={rejectWithdrawal}>
          Disaprove
        </Button>
        <Button variant="primary" onClick={processWithdrawal}>
          Approve
        </Button> </>
        : 
        ''
        }
      </Modal.Footer>
      </Modal>
    </>
  );
}
