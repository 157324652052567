import React, { useEffect, useState } from 'react'
import { getAllAttributes, getAllListings, getEventsByListingId } from '../utils';
import ListingsWithFilters from '../ListingsWithFilters';
import FloatingButtons from '../frontend/FloatingButtons';
export default function Listings() {

    // Sample columns
    const columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Price', accessor: 'price' },
        { Header: 'backgroundImage', accessor: 'backgroundImage' },
        { Header: 'location', accessor: 'location' },
        // ... more columns
      ];

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    const apiKey = 'AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg';
  
      const [listings, setListings] = useState([]);
      const [transformedData, setTransformedData] = useState([]);
    
      const getLocationName = async (lat, lng) => {
        try {
          const dropOffResponse = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
          );
    
          const dropOffdata = await dropOffResponse.json();
          const dropoffName =
            dropOffdata.results[0]?.formatted_address || 'Location not found';
          return dropoffName;
        } catch (error) {
          console.error('Error fetching location name:', error);
          return null;
        }
      };

      const getLocationInfo = async (lat, lng) => {
        try {
          const dropOffResponse = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
          );
      
          const dropOffdata = await dropOffResponse.json();
          const locationInfo = dropOffdata.results[0] || null;
          return locationInfo;
        } catch (error) {
          console.error('Error fetching location info:', error);
          return null;
        }
      };
      
    
      const fetchListings = async () => {
        try {
          const listings = await getAllListings();
          setListings(listings);
    
          const transformedDataPromises = listings.map(async (listing) => {

            console.log('transformedDataPromises');
            console.log(listing.boatStatus);

            if(listing.boatStatus == 'Active'){
                console.log('Boat is active');


                const lockedDates = await getEventsByListingId(listing.id);

            
              const locationName = await getLocationName(
                  listing.storageAddress['lat'],
                  listing.storageAddress['lng']
              );

              const locationInfo = await getLocationInfo(
                listing.storageAddress['lat'],
                listing.storageAddress['lng']
              );
              
              return {
                id: String(listing.id),
                name: listing.listingTitle,
                price: '',
                backgroundImage: listing.featured_image,
                selectedAttributes: listing.features,
                location: locationName,
                locationInfo: locationInfo,
                lockedDates: lockedDates,
              };

            }else{
              return '';
            }
          });
    
          const transformedData = await Promise.all(transformedDataPromises);

          console.log("New Transformed Data: ",transformedData);
    
          setTransformedData(transformedData);
        } catch (error) {
          console.error('Error fetching listings:', error);
        }
      };
    
      useEffect(() => {
        fetchListings();
      }, []);
    
    return (
        <>
        <FloatingButtons/>
        <section className='cs-bg-dark py-4 cs-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <h1 className='fw-bold text-white'>Our Boats</h1>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                    <ListingsWithFilters data={transformedData} columns={columns}/>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
