import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

export default function WaiverThankYou() {
  return (
    <>
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Thank You!</Card.Title>
              <Card.Text>
                Your waiver submission was successful, visit your <a href="/dashboard">dashboard</a> to review your booking details or contact the boat owner with any further questions.
                <br/>
                To start the trip, go to dashboard, select the 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  )
}
