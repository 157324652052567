import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OwnerVerificationApprovedEmailBody, OwnerVerificationRejectedEmailBody, RenterVerificationApprovedEmailBody, RenterVerificationRejectedEmailBody, getOwnerMetaByID, getReferralCodeByUserId, getUserByID, getUserMetaByID, proMemberRejectedStatusBody, proMemberStatusBody, sendEmail, updateProStaffStatus, updateUserStatusById } from '../utils';
import { Button, Col, Row } from 'react-bootstrap';
import { getDatabase, push, ref, update } from 'firebase/database';
import { Alert } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'
import { useDateRangePickerDefaultizedProps } from '@mui/x-date-pickers-pro/DateRangePicker/shared';


export default function User() {
  const { dynamicId } = useParams();
  const [userData, setUserData] = useState(null);
  const [userMeta, setUserMeta] = useState(null);
  const [ownerMeta, setownerMeta] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]);
  const [payoutAccounts, setPayoutAccounts] = useState(null);
  const [initialStatus, setInitialStatus] = useState('Pending');
  const [rejectionReason, setRejectionReason] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDetails = await getUserByID(dynamicId);
        const userMetaDetails = await getUserMetaByID(dynamicId);
        const ownerMetaDetails = await getOwnerMetaByID(dynamicId);
        const ReferralCodeByUserId = await getReferralCodeByUserId(dynamicId);
        const userAnswers = userMetaDetails.userAnswers;
        let allQuestions = [];
        if (userDetails.role === 'Boat Owner') {
          if (userAnswers.textQuestions) {
            allQuestions = [...userAnswers.textQuestions];
          } else {
            allQuestions = [];
          }
        } else {
          allQuestions = [];
          if (userAnswers.multiChoiceQuestions) {
            allQuestions = [...allQuestions, ...userAnswers.multiChoiceQuestions];
          }
          if (userAnswers.textQuestions) {
            allQuestions = [...allQuestions, ...userAnswers.textQuestions];
          }
        }
        
        allQuestions.sort((a, b) => (a.questionIndex || 0) - (b.questionIndex || 0));
        const questionsWithAnswers = allQuestions.map(({ question, answer }) => ({ question, answer }));

        setReferralCode(ReferralCodeByUserId);
        setUserData(userDetails);
        updateUser({
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          role: userDetails.role,
          status: userDetails.status,
          phone: userDetails.phone,
          avatar: userDetails.avatar,
        })
        setAllQuestions(questionsWithAnswers);
        setRejectionReason(userDetails.rejectionReason);
        setUserMeta(userMetaDetails);
        setownerMeta(ownerMetaDetails);
        // Check if 'payoutAccounts' is available in 'ownerMetaDetails'
        if (ownerMetaDetails && ownerMetaDetails.payoutAccounts) {
          setPayoutAccounts(ownerMetaDetails.payoutAccounts);
        }
        // Set the initial value of the select based on userData.status
        setInitialStatus(userDetails.status || 'Pending');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };    

    fetchUserData();
  }, [dynamicId]);

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setInitialStatus(newStatus);
  
    // If you want to update the rejection reason immediately, you can still keep this line
    setRejectionReason('');
  };

  const updateProMemberStatus = (newStatus) => {
    // Update ownerMeta state with the new value of proMemberStatus
    setownerMeta(prevOwnerMeta => ({
      ...prevOwnerMeta, // spread previous state
      proMemberStatus: newStatus // update proMemberStatus
    }));
  }

  const handleApproveProMember = async () => {
    try {
      await updateProStaffStatus(dynamicId, 'Approved')
      const memberDetails = await getUserByID(dynamicId);
      const database = getDatabase();
      const ownerMetaRef = ref(database, `refferalCodes`);
      const referCode = memberDetails.firstName + memberDetails.lastName + 30;
      await push(ownerMetaRef, {code: referCode, userId: dynamicId});
      const applicantName = memberDetails.firstName + ' ' + memberDetails.lastName;
      const emailBody = await proMemberStatusBody(applicantName, 'Approved', referCode);
      sendEmail (memberDetails.email, 'Pro Member Application - Status', emailBody);
      updateProMemberStatus('Approved');
      const ownerMetaDetails = await getOwnerMetaByID(dynamicId);
      setownerMeta(ownerMetaDetails);
    } catch (error) {
      console.log("Error", error);
    }
  }
  const handleRejectProMember = async () => {
    try {
      await updateProStaffStatus(dynamicId, 'Rejected')
      const memberDetails = await getUserByID(dynamicId);
      const applicantName = memberDetails.firstName + ' ' + memberDetails.lastName;
      const emailBody = await proMemberRejectedStatusBody(applicantName, 'Approved');
      sendEmail (memberDetails.email, 'Pro Member Application - Status', emailBody);
      updateProMemberStatus('Rejected');
      const ownerMetaDetails = await getOwnerMetaByID(dynamicId);
      setownerMeta(ownerMetaDetails);
    } catch (error) {
    }
  }

  const [updatedUserData, updateUser] = useState({
    firstName: '',
    lastName: '',
    age: '',
    phone: '',
    status: '',
    role: '',
  })

  const handleUpdateUser = (e) => {
    const { name, value } = e.target;
    updateUser((prevData) => ({ ...prevData, [name]: value }));
    console.log(updatedUserData);
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const handleSave  = async (event) => {
    try {
        event.preventDefault();
        setLoading(true);
        const userData = updatedUserData;
        const iniStatus = initialStatus;
        const newStatus = userData.status;
        if(newStatus != iniStatus) {
        updateUserStatusById(dynamicId, newStatus, rejectionReason)

        const userName = userData.firstName + ' ' + userData.lastName;
        const originalUserData = await getUserByID(dynamicId);
        const userEmail = originalUserData.email;
        const url = 'https://www.bbrbassboatrentals.com/';

        if(userData.role == 'Renter'){
          if(newStatus == 'Active'){
            const subject = `Congratulations! You're now verfied`;
            const bodyData = await RenterVerificationApprovedEmailBody (userName, url);
            sendEmail (userEmail, subject, bodyData);
          }else if(newStatus == 'Reject'){
            const subject = `You have received a new message from BBR`;
            const reason = rejectionReason;
            const bodyData = await RenterVerificationRejectedEmailBody (userName, url, reason);
            sendEmail (userEmail, subject, bodyData);
          }
        }else{
          console.log("Boat Owner");
          if(newStatus == 'Active'){
            console.log("Active");
            const subject = `Congratulations! You're now verfied`;
            const bodyData = await OwnerVerificationApprovedEmailBody (userName, url);
            sendEmail (userEmail, subject, bodyData);
          }else if(newStatus == 'Reject'){
            console.log("Reject");
            const subject = `You have received a new message from BBR`;
            const reason = rejectionReason;
            const bodyData = await OwnerVerificationRejectedEmailBody (userName, url, reason);
            sendEmail (userEmail, subject, bodyData);
          }
        }
      }
      const userRef = ref(getDatabase(), `users/${dynamicId}`);
      await update(userRef, {firstName: userData.firstName, lastName: userData.lastName, phone: userData.phone, role: userData.role})
      setLoading(false);
      setSuccess('User has been updated.');
      setTimeout(() => {
        setSuccess('');
      }, 1400)
    } catch (error) {
      console.error('Error updating user status:', error);
      setLoading(false);
    }
  };

  if (!userData) {
    // You may want to display a loading spinner or message while data is being fetched
    return <div>Loading...</div>;
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };
    return date.toLocaleString('en-US', options);
};

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>User</h1>
          </div>
        </div>
      </div>

    <section>
      <div>
        <div class="row">
          <div class="col-lg-4">
            <div className='row'>
              <div className='col-12'>
              <div class="card mb-4">
              <div class="card-body text-center">
                <img src={userData && userData.avatar ? userData.avatar : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"} alt="avatar"
                  class="rounded-circle img-fluid" style={{width: "150px"}} />
                <h5 class="my-3">{userData.firstName} {userData.lastName}</h5>
                <p class="text-muted mb-1">{userData.bbrId}</p>
                {/* <p class="text-muted mb-1">{userData.role}</p> */}
                <div class="d-flex justify-content-center mb-2 mt-4">
                  <select
                    className="form-select"
                    name="role"
                    style={{width: '60%', marginTop: '-20px'}}
                    aria-label="Default select example"
                    value={updatedUserData.role}
                    onChange={(e) => handleUpdateUser(e)}
                  >
                    <option value="Boat Owner">Boat Owner</option>
                    <option value="Renter">Renter</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>
                <form onSubmit={handleSave}>
                <div class="d-flex justify-content-center mb-2 mt-4">
                  <select
                    className="form-select"
                    name="status"
                    aria-label="Default select example"
                    value={updatedUserData.status}
                    onChange={(e) => handleUpdateUser(e)}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Active">Approve</option>
                    <option value="Reject">Deny</option>
                  </select>
                </div>
                {updatedUserData && updatedUserData.status === 'Reject' && (
                  <div className="d-flex justify-content-center mb-2 mt-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Denial reason"
                      value={rejectionReason}
                      required
                      onChange={(e) => setRejectionReason(e.target.value)}
                    />
                  </div>
                )}
                <div className="">
                {success && <Alert severity='success'>{success}</Alert>}
                  <button className="btn mt-2 btn-primary" type='submit'>
                  {loading ? <ThreeDots
                    visible={true}
                    height="22"
                    width="22"
                    color="#fff"
                    radius="20"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    /> : 'Update User'}
                  </button>
                 
                </div>
                </form>
              </div>
              
            </div> 
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
              {ownerMeta && ownerMeta.becomeProRequests && (
                <>
                  {Object.values(ownerMeta.becomeProRequests).map((request, index) => (
                    <div key={index} className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <Row>
                                        <Col xs={6}>
                                            <h5>Become Pro Request</h5>
                                            <p>{formatTimestamp(request.timestamp)}</p>
                                        </Col>
                                        <Col xs={6} className='d-flex align-items-center justify-content-end gap-3'>
                                        {ownerMeta.proStaffStatus === 'Pending' ? 
                                          <div className='d-flex gap-2'>
                                            <Button onClick={handleApproveProMember}>Approve</Button>
                                            <Button onClick={handleRejectProMember} className='btn-danger'>Reject</Button>
                                          </div>
                                        : (ownerMeta.proStaffStatus === 'Rejected' ? 
                                          <Alert severity='error'>This Pro Staff Request has been rejected.</Alert> 
                                        : (ownerMeta.proStaffStatus === 'Approved' ? 
                                          <Alert severity='success'>This Pro Staff Request has been Approved.</Alert> 
                                        : ''))}
                                      </Col>
                                    </Row>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50%' }}>Question</th>
                                                <th style={{ width: '50%' }}>Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {request.formData && request.formData.map((data, dataIndex) => (
                                                <tr key={dataIndex}>
                                                    <td>{data.question}</td>
                                                    <td>{data.answer}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                  ))}
                </>
              )}
              </div>
            </div>           
          </div>
          <div class="col-lg-8">
            <div class="card mb-4">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3 d-flex align-items-center">
                    <p class="mb-0">First Name</p>

                  </div>
                  <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control form-control-solid-bordered"
                    value={updatedUserData.firstName}
                    name="firstName"
                    onChange={(e) => handleUpdateUser(e)}
                    label="First Name" 
                    />
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3 d-flex align-items-center">
                    <p class="mb-0">Last Name</p>

                  </div>
                  <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control form-control-solid-bordered"
                    value={updatedUserData.lastName}
                    name="lastName"
                    onChange={(e) => handleUpdateUser(e)}
                    />
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0">Email</p>
                  </div>
                  <div class="col-sm-9">
                    <p class="text-muted mb-0">{userData.email}</p>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3 d-flex align-items-center">
                    <p class="mb-0">Phone</p>

                  </div>
                  <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control form-control-solid-bordered"
                    value={updatedUserData.phone}
                    name="phone"
                    onChange={(e) => handleUpdateUser(e)}
                    />
                  </div>
                </div>
                <hr/>
                {/* <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0">Address</p>
                  </div>
                  <div class="col-sm-9">
                    {userMeta && <p class="text-muted mb-0">{userMeta.location}</p>}
                  </div>
                </div>
                <hr/> */}
                <div class="row">
                  <div class="col-sm-3">
                    <p class="mb-0">Age</p>
                  </div>
                  <div class="col-sm-9">
                    {userMeta && <p class="text-muted mb-0">{userMeta.age}</p>}
                  </div>
                </div>
                {referralCode && <>
                  <hr/>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">Refferal Code</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{referralCode}</p>
                    </div>
                  </div>
                </>}
              </div>
            </div>
            {userMeta && (
              <div className='row'>
                <div className='col'>
                  <div className='card p-3'>
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{width: '50%'}}>Question</th>
                          <th style={{width: '50%'}}>Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        { userData.role == 'Renter' || userData.role == 'Boat Owner' ?
                        allQuestions.map((question, index) => (
                          <tr key={index}>
                            <td>{question.question}</td>
                            <td>{question.answer}</td>
                          </tr>
                        )): ''
                        }
                        {/* {userMeta.userAnswers.textQuestions.map((question, index) => (
                          <tr key={index}>
                            <td>{question.question}</td>
                            <td>{question.answer}</td>
                          </tr>
                        ))} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>


      <div className='row'>
        {payoutAccounts && (
          <div className='col'>
            <div className='card p-4'>
              <h2>Payout Accounts</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>Account Type</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>Currency</th>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>State</th>
                    <th>Zip Code</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(payoutAccounts).map(([accountType, accountDetails]) => (
                    <tr key={accountType}>
                      <td>{accountType}</td>
                      <td>{accountDetails.accountHolderAddress}</td>
                      <td>{accountDetails.accountHolderCity}</td>
                      <td>{accountDetails.country}</td>
                      <td>{accountDetails.currency}</td>
                      <td>{accountDetails.accountHolderEmail}</td>
                      <td>{accountDetails.accountHolderFirst}</td>
                      <td>{accountDetails.accountHolderLast}</td>
                      <td>{accountDetails.accountHolderState}</td>
                      <td>{accountDetails.accountHolderZip}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          )}
        </div>
      
      </div>
    </section>
    </>
  );
}
