import {useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'
import FloatingButtons from './FloatingButtons'

export default function Cancellation() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <>
        <FloatingButtons/>
        <section className='cs-bg-dark py-4 cs-section'>
            <div className='container text-white'>
                <Row>
                    <Col xs={12} md={4}>
                        <img className='rounded' width={'100%'} src="/assets/frontend/images/bbrboat.webp"/>
                    </Col>
                    <Col xs={12} md={8}>
                        <Row className=''>
                            <Col xs={12} className='d-flex flex-column margin-top-mobile-40'>
                                <h1>Cancellation</h1>
                                <h4 className='mt-3'>Weather related cancellation</h4>
                                <p className='m-0'>Bass fishing is an outdoor activity, weather is a factor which adds to the challenge and fun in participation. A boat owner is not obligated to cancel a reservation due to bad weather unless there is a named storm at the pick up location. Cancellation of the booking is at the sole discretion of the boat owner. See cancellation policy. </p>
                                <h4 className='mt-4'>Cancellation policy</h4>
                                <p className='m-0'>If cancellation occurs 2 weeks prior to your reservation there will be a full refund. Within 2 weeks and up to 48 hours of the booking we will keep a $200 booking fee from your refund amount. Cancellations within 48 hours prior to your reservation will be charged the full amount unless you re-schedule to a date within 90 days of the original reservation. No shows for any reservation will be charged the full amount.
If the tournament is cancelled prior to the reservation start "by the tournament director", the boat owner will cancel and refund your booking at no cost.
If the tournament is cancelled on the day of the tournament "by the tournament director" the boat owner will pro-rate your refund based on the daily rate e.g. if you return the boat 1 day early, the boat owner could refund the cost of that additional day rental. For example, if a 2 day rental cost $700 and a 1 day rental cost $500, a one day early return will trigger a $200 refund. All refunded fees are guidelines and are negotiable between boat owner and renter. It is recommended that both parties be fair and considerate to maintain a good relationship for all future rental opportunities. BBR is not responsible for settling refund disputes between boat owner and renter.   </p>
                            </Col>
                        </Row>   
                    </Col>
                </Row>
            </div>
        </section>
        </>
      )
}
