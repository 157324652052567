import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import FloatingButtons from './FloatingButtons';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { getAllBoatOwners, getBookingsByListingId, getListingByUserID } from '../utils';
import Select from 'react-select'

export default function ViewFillWaivers() {

  const Navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [boats, setBoats] = useState([]);
  const [bookings, setBookings] = useState([]);

  const [selectedBoat, setSelectedBoat] = useState({});

  const handleOnSelect = async (item) => {
    const ownerBoats = await getListingByUserID(item.value);
    console.log(ownerBoats);
    const activeListings = ownerBoats?.filter(listing => listing.boatStatus === "Active") || [];
    if(activeListings) {
      let boats = activeListings.map((boat) => {
        return {
          value: boat.id,
          label: boat.listingTitle,
          image: boat.featured_image
        }
      });
      setBoats(boats);
    } else {
      setBoats([]);
    }
    setBookings([]);
    setSelectedBoat([]);
  }

  const handleBoatOnSelect = async (item) => {
    setSelectedBoat(item);
    const bookingsData = await getBookingsByListingId(item.value);
    console.log(bookingsData);
    
    if (bookingsData) {
      // Filter bookings with status 'tripStarted' or 'Waiver Pending'
      const filteredBookings = bookingsData.filter(booking => 
        booking.status === 'tripStarted' || booking.status === 'Waiver Pending' || booking.status === 'Paid'
      );
      
      setBookings(filteredBookings);
    } else {
      setBookings([]);
    }
  }
  

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const boatOwners = await getAllBoatOwners();
        console.log(boatOwners);
  
        let newOptions = boatOwners.map((owner) => {
          return {
            value: owner.userId, // Adding index to ensure uniqueness
            label: owner.firstName + ' ' + owner.lastName
          };
        });
  
        setOptions(newOptions);

        console.log(newOptions);
      } catch (error) {
        console.log('Error fetching boat owners: ', error.message);
      }
    };
    fetchOwners();
  }, []);

  return (
    <>
      <FloatingButtons />
      <section className='cs-bg-dark py-4 cs-section'>
        <Container>
          <Row>
            <h1 className='text-white'>Submit Your Waivers</h1>
          </Row>
          <Row>
            <Col xs={6} style={{zIndex: '9'}}>
              <h5 style={{fontSize: '15px'}} className='text-white'>Enter Your Boat Owner</h5>
              <Select options={options} onChange={handleOnSelect} />
            </Col>
            <Col xs={6}>
              <h5 style={{fontSize: '15px'}} className='text-white'>Select Boat</h5>
              <Select options={boats} value={selectedBoat} onChange={handleBoatOnSelect} />
            </Col>
          </Row>
          {selectedBoat && selectedBoat.label &&
          <>
            <Row style={{marginTop: '20px'}}>
              <Col xs={12} md={5}>
                <img className='listing-image-waiver' src={selectedBoat?.image ? selectedBoat.image : ''}/>
              </Col>
              <Col className='mobile-mt' xs={12} md={7}>
                <h3 className='text-white'>{selectedBoat.label} BOOKINGS</h3>
                <hr/>
                {bookings && bookings.map((booking, index) => (
                  <>
                    <Row className='test mt-3 p-2' key={index}>
                      <h4 className='text-white'>{booking?.listingTitle}</h4>
                      <h5 className='text-white'>{new Date(booking.timestamp).toLocaleString()}</h5>
                      <Row className='d-flex p'>
                        <Col xs={12} className='d-flex gap-3'>
                          <Button className='btn btn-light cs-bg-orange text-cs-dark' onClick={() => Navigate(`/waivers/adultWaiver/${booking.id}`)}>Adult Waiver</Button>
                          <Button className='btn btn-light cs-bg-orange text-cs-dark' onClick={() => Navigate(`/waivers/minorWaiver/${booking.id}`)}>Minor Waiver</Button>
                        </Col>
                      </Row>
                    </Row>
                    <hr/>
                  </>
                ))}
              </Col>
            </Row>
          </>
          }
        </Container>
      </section>
    </>
  );
}
