// Payment.js
import React, { useEffect, useState } from 'react';
import DataTable from '../DataTable';
import { Elements } from '@stripe/react-stripe-js';
import PaymentFormTest from '../PaymentFormTest';
import { getOfferDetailsByBookingId } from '../utils';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_test_51NViwnAPnaSENdrNdQ7IZ9rfmEQwx00OmmDxW5Q3vY1V7rTpKWJKqHFRAiG6isPNrboNNwDgTKYKDEypWMcWBUPc00XQle7IqR');


export default function DemoBookingPayment() {

    const [storedPrice, setStoredPrice] = useState(null);
    const [offerData, setOfferData] = useState(null);

    const queryParameters = new URLSearchParams(window.location.search)
    const bookingId = queryParameters.get("bookingId");

    useEffect(() => {
      const fetchDetails = async () => {
          try {
            const offerDetails = await getOfferDetailsByBookingId(bookingId);
            const amountFinal = parseInt(offerDetails['offerPrice']+'00');
            setStoredPrice(amountFinal);
            setOfferData(offerDetails);
          } catch (error) {
            console.error('Error fetching listing details:', error);
          }
      };
      fetchDetails();
  }, []); // Empty dependency array means the effect runs once when the component mounts

  return (
    <>
      <div className="row">
        <div className="col">
          
              <div className='mt-4'>
                <Elements stripe={stripePromise}>
                  <PaymentFormTest amount={storedPrice} />
                </Elements>
              </div>
            </div>
      </div>
    </>
  );
}
