import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import FloatingButtons from './FloatingButtons';

export default function MyWaiverForm() {
  const [waiverId, setWaiverId] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const handleChange = (e) => {
    setWaiverId(e.target.value);
  };

  return (
    <>
      <FloatingButtons />
      <section className='cs-bg-dark py-4 cs-section'>
        <Container>
          <Row>
            <Col>
              <h1 className='text-white'>View Your Signed Waiver</h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId='waiverId'>
                  <Form.Label className="text-white">Waiver Submission ID</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Waiver Submission ID'
                    value={waiverId}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant='light' className='mt-2' type='submit'>
                  View Signed Waiver
                </Button>
              </Form>
              {submitted && <Navigate to={`/my-waiver/${waiverId}`} />}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
