import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import FloatingButtons from './FloatingButtons';
import { Link } from 'react-router-dom';

export default function PendingApproval() {
  return (
    <>
    <FloatingButtons/>
    <section className='cs-bg-dark p-4 d-flex align-items-center' style={{height: '400px'}}>
      <Container className="p-4">
        <Row className="justify-content-center">
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Thank You for Registering at BBR!</Card.Title>
                <Card.Text>
                  Your account has been registered and you have been logged in. If you want to rent Bass Boats, you will need to get yourself verified!
                </Card.Text>
                <Link to={'/listings'} className='btn btn-primary'>Start Renting</Link>
                <Link to={'/dashboard/get-verified'} className='btn btn-primary mx-2'>Get Verified</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
    
    </>
  )
}
