import React from 'react'

export default function AddUser() {
  return (
    <>
    <div class="row">
        <div class="col">
            <div class="page-description">
                <h1>Add User</h1>
            </div>
        </div>
    </div>
    
    </>
  )
}
