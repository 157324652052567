import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getFirestore } from "firebase/firestore";


var FIREBASE_API_KEY = "AIzaSyBtRfZ-3R89jkhvjnT4yiFnDX0KO4p-NLo"
var FIREBASE_AUTH_DOMAIN = "bbr-app-8cef1.firebaseapp.com"

var FIREBASE_PROJECT_ID = "bbr-app-8cef1"
var FIREBASE_STORAGE_BUCKET = "bbr-app-8cef1.appspot.com"
var FIREBASE_MESSAGING_SENDER_ID = "979212341877"
var FIREBASE_DATABASE_URL = "https://bbr-app-8cef1-default-rtdb.firebaseio.com"
var FIREBASE_APP_ID = "1:979212341877:web:3b92742ae293885f071e3c"
var FIREBASE_MEASUREMENT_ID = "G-LJLFXFBSTC"

const app = firebase.initializeApp({
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    databaseURL: FIREBASE_DATABASE_URL,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID
});

export default app;
export const auth = app.auth();
export const db = getFirestore(app);
