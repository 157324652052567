import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSignedWaiverByID, getWaiverDataByName, getWaiverDescriptionByName } from '../utils';
import { Col, Container, Row } from 'react-bootstrap';

export default function MyWaiver() {
    const { dynamicId } = useParams();
    const [signedWaiver, setSignedWaiver] = useState(null);
    const [waiverDescription, setWaiverDescription] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchWaiverData = async () => {
            try {
                const signedWaver = await getSignedWaiverByID(dynamicId);
                if (!signedWaver) {
                    setError('Waiver could not be found. Please verify your submission ID and try again.');
                    return;
                }
                const basicWaiver = await getWaiverDataByName(signedWaver.waiverName);
                const description = await getWaiverDescriptionByName(signedWaver.waiverName);

                console.log("signedWaver", signedWaver);
                setSignedWaiver(signedWaver);
                setWaiverDescription(description);
            } catch (error) {
                console.error('Error fetching waiver data:', error);
                setError('Error fetching waiver data. Please try again later.');
            }
        };

        fetchWaiverData();
    }, [dynamicId]);

    return (
        <>
            <section className='p-4 mb-4 d-flex align-items-center'>
                <Container className='mb-4'>
                    <Row className='card p-5 d-flex flex-row align-items-center'>
                        <Col xs={4}>
                            <img className='img-fluid' src="/assets/backend/images/logo.webp" alt="Logo"></img>
                        </Col>
                        <Col xs={8}>
                            <h3 className='' style={{ color: '#27313F', fontWeight: '800', textAlign: 'center' }}>BBR BASS BOAT RENTALS, LLC</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='m-0 p-0 mt-4'>
                            <div className="card p-4">
                                {error ? (
                                    <div className="alert alert-danger" role="alert">
                                        {error}
                                    </div>
                                ) : signedWaiver ? (
                                    <>
                                    <div className="row">
                                      <div className="col">
                                        <div className="page-description">
                                          <h1>Your Signed {signedWaiver.waiverName}</h1>
                                          <div className='waiverDescrption mt-4' dangerouslySetInnerHTML={{ __html: waiverDescription }} />
                                        </div>
                                      </div>
                                    </div>
                                    <hr/>
                                    <form>
                                        {signedWaiver && signedWaiver.questions && signedWaiver.questions.map((question, index) => (
                                            <div key={`${question.question}-${question.id}`}>
                                                <label className="form-label form-label mt-2">{question.question}:</label>
                                                <br />
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control form-control"
                                                    value={question.answer || ''}
                                                    readOnly
                                                    disabled
                                                />
                                            </div>
                                        ))}
                                    </form>
                                    </>
                                    
                                ) : (
                                    <div>Loading...</div>
                                )}
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}
