import React from 'react'
import CreateListing from '../createListing'

export default function AddListing() {
  return (
    <>
    <div class="row">
        <div class="col">
            <div class="page-description">
                <h1>Add Boat</h1>
            </div>
        </div>
    </div>

    <CreateListing/>
    
    </>
  )
}
