import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import FloatingButtons from './FloatingButtons';

export default function RejectedApproval() {
  return (
    <>
    <FloatingButtons/>
    <section className='cs-bg-dark p-4 d-flex align-items-center' style={{height: '400px'}}>
      <Container className="p-4">
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className='bg-danger'>
              <Card.Body>
                <Card.Title className='text-white' style={{fontSize: '20px'}}>We regret to inform you that your registration request at BBR has been rejected!</Card.Title>
                <Card.Text className='text-white'>
                    Our services are only available to individuals aged 21 and above. We appreciate your interest, but we are unable to proceed with your request at this time.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
    
    </>
  )
}
