import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getAllAttributes } from './utils';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ListingsWithFilters = ({ data, columns }) => {
  const [search, setSearch] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [storageAddress, setStorageAddress] = useState('');
  const [attributeOptions1, setAttributeOptions1] = useState('');
  const [locationNames, setLocationNames] = useState({});
  const [attributeOptions2, setAttributeOptions2] = useState('');
  const [availableStartDate, setAvailableStartDate] = useState(null);
  const [availableEndDate, setAvailableEndDate] = useState(null);


  const handleStartDateChange = (date) => {
    setAvailableStartDate(date);
    if (!availableEndDate || date > availableEndDate) {
      setAvailableEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    setAvailableEndDate(date);
  };


  useEffect(() => {
    const fetchListings = async () => {
      try {
        const attributeData = await getAllAttributes();

        setAttributeOptions1(attributeData[1]);
        setAttributeOptions2(attributeData[2]);

        
      } catch (error) {
        console.error("Error fetching listings:", error);
      }
    };

    fetchListings();
  }, []);


  const getNearbyLocations = async (lat, lng, radius) => {
    try {
      const dropOffResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&radius=${radius}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );

      const dropOffdata = await dropOffResponse.json();
  
      return dropOffdata;
    } catch (error) {
      console.error('Error fetching nearby locations: ', error);
      return null;
    }
  };

  const cpatainArray = Object.values(attributeOptions1);
  const optionsArray = Object.values(attributeOptions2);

  const [nearbyLocations, setNearbyLocations] = useState({});

  const handlesStorageSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      const nearbyLocations = await getNearbyLocations(latLng.lat ,latLng.lng ,50000);
      setStorageAddress(results[0].formatted_address);
      console.log('selectedAddress');
      console.log(latLng);
      
      setNearbyLocations(nearbyLocations);
    } catch (error) {
      console.error('Error fetching geolocation:', error);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
  );

  const memoizedColumns = useMemo(() => columns, [columns]);

  const containerStyle = {
    minHeight: '350px',
    borderRadius: '20px',
    padding: '3px',
    backgroundSize: 'cover',
    margin: '0px',
    backgroundPosition: 'center',
  };

  const priceTagStyle = {
    background: 'white',
    color: 'black',
    padding: '6px 15px',
    borderRadius: '10px',
    width: 'max-content',
    marginTop: '10px',
  };

  const boatFeaturesStyle = {
    gap: '20px',
    background: 'grey',
    borderRadius: '10px',
    color: 'white',
    padding: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  };

  const filteredPage = page.filter((row) => {
    const includesSearch = Object.values(row.values).some((value) =>
      value?.toString().toLowerCase().includes(search.toLowerCase())
    );
  

    const includesAttributes =
      selectedAttributes.length === 0 ||
      (row.original.selectedAttributes &&
        selectedAttributes.some((attr) =>
          row.original.selectedAttributes.includes(attr)
        ));
  
    console.log('nearbyLocations.results');
    console.log(nearbyLocations.results);
    const includesLocation = !nearbyLocations || nearbyLocations.results?.some(
      (location) =>
        location &&
        location.formatted_address &&
        row.values.location &&
        row.values.location.toLowerCase &&
        location.formatted_address.toLowerCase &&
        row.values.location.toLowerCase().includes(location.formatted_address.toLowerCase())
    );

    console.log('nearbyLocations');
    console.log(nearbyLocations);


    if (!nearbyLocations || (Object.keys(nearbyLocations).length === 0 && nearbyLocations.constructor === Object) || nearbyLocations === '') {
      return includesSearch && includesAttributes;
    } else {
      
      return includesSearch && includesAttributes && includesLocation;
    }
    
  });

  const handleSearchInputChange = (newAddress) => {
    if (!newAddress) {
      // Clear storageAddress and nearbyLocations when the input is empty
      setStorageAddress('');
      setNearbyLocations({});
    }
  };
  
  const handleCheckboxChange = (option) => {
    setSelectedAttributes((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((attr) => attr !== option)
        : [...prevSelected, option]
    );
  };

  return (
    <div>
      <Row className="mb-2 search-row">
        <Col md={12}  xs={12}>
          <Row>
            <Col xs={12} md={8}>
              <div className="mb-2">
                <input
                  type="text"
                  placeholder="Search a boat..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="form-control form-control-solid-bordered"
                  id="floatingInput"
                />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className='locationDiv'>
                <PlacesAutocomplete
                  value={storageAddress}
                  onChange={(newAddress) => {
                    setStorageAddress(newAddress);
                    handleSearchInputChange(newAddress);
                  }}
                  className="form-control form-control-solid-bordered"
                  onSelect={handlesStorageSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <div className="input-group">
                        <input
                          {...getInputProps({
                            placeholder: 'Search by Lake / Location...',
                            className: 'location-search-input form-control',
                            name: "location"
                          })}
                        />
                      </div>
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className: 'location-suggestion form-control'
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </Col>
          </Row>
        </Col>
        {/* <Col md={4}  xs={12}>
          <Row>
            <Col xs={6}>
                <DatePicker
                  selected={availableStartDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={availableStartDate}
                  endDate={availableEndDate}
                  className="form-control form-control-solid-bordered"
                  placeholderText="Available From"
                />
            </Col>
            <Col xs={6}>
                <DatePicker
                  selected={availableEndDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={availableStartDate}
                  endDate={availableEndDate}
                  className="form-control form-control-solid-bordered"
                  minDate={availableStartDate}
                  placeholderText="Available Till"
                />
            </Col>
          </Row>   
        </Col>  */}
      </Row>
          
      <Row>
        <Col xs={12} md={3}>
          <div className="card p-4 filter-card">
            <div className="attributes">
              <h5>Filter by boat features</h5>
              {optionsArray.map((option, index) => (
                <div className="form-check" key={index}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`checkbox-${index}`}
                    value={option}
                    onChange={() => handleCheckboxChange(option)}
                    checked={selectedAttributes.includes(option)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${index}`}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
            
          </div>
        </Col>
        <Col xs={12} md={9}>
          <div>
            <Row>
              <Col><h4 className='text-white'>{storageAddress ? 'Showing Results for: '+storageAddress : ''}</h4></Col>
            </Row>
            <Row>
              {filteredPage.map((row, rowIndex) => (
                <Col key={rowIndex} md={4} className="mb-4">
                  <Link
                    to={`/listing/${row.values.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <div
                      className="row productCard p-3 position-relative"
                      style={{
                        ...containerStyle,
                        backgroundImage: `url(${
                          row.values.backgroundImage ||
                          'https://images.pexels.com/photos/19415811/pexels-photo-19415811/free-photo-of-back-view-of-man-in-trench-coat-and-hat.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                        })`,
                      }}
                    >
                      {/* Overlay using ::before pseudo-element */}
                      <div
                        className="overlay"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          content: '""',
                          backgroundColor: 'rgba(0, 0, 0, 0.22)', // Adjust the color and opacity as needed
                        }}
                      ></div>
                      <div
                        className="col-12"
                        style={{
                          justifyContent: 'space-between',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <p></p>
                        <div className="row" style={{zIndex: '9'}}>
                          <div className="col-12"></div>
                          <div className="row">
                            <h3
                              style={{ padding: '0px', color: 'white' }}
                            >
                              {row.values.name}
                            </h3>
                            <p style={{ color: 'white' }}>
                            {row.values.location}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>

          <Row className="mt-4">
            {/* <Col xs={6} className="d-flex align-items-center">
              <span className="m-2 text-white">
                Page{' '}
                <strong>
                  {pageIndex + 1} of {page.length}
                </strong>{' '}
              </span>
            </Col> */}
            <Col xs={12} className="d-flex justify-content-center">
              <button
                className={`btn ${
                  canPreviousPage ? 'btn-primary' : 'btn-light'
                } m-1`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous Page
              </button>
              <button
                className={`btn ${
                  canNextPage ? 'btn-primary' : 'btn-light'
                } m-1`}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next Page
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ListingsWithFilters;
